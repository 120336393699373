@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oleo+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");

.DayPicker-Caption {
  display: table-captio !important;
  margin-bottom: 0.5em !important;
  text-align: center !important;
}
.DayPicker-Day.DayPicker-Day--selected {
  border-radius: 9px;
}

ul#cart-button-update-list {
  background: rgb(30, 126, 80);
  border-radius: 1em;
  color: white !important;
  min-width: 120px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 5px 10px !important;
}

.tom-varukorg-class {
  position: sticky !important;
  margin-top: 10% !important;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  z-index: 1;
}

.urgent-message-holder {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5px !important;
  background: #ffc107;
  color: #000;
  display: flex;
  font-weight: bolder;
  flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-size: 0.5rem !important;
}

.ProductLineInfoMiniCart.col-lg-4.col-md-4 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.shopping-cart-icon-div {
  cursor: pointer;
}

.searchbardiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

button.btn.btn-outline-success {
  color: white;
}
.no-borders-please.card {
  border: none !important;
}
.svg-inline--fa {
  margin-right: 5px;
  height: 1.7em !important;
}
button.newleveransbutton.btn.btn-primary {
  display: inline-block !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  border: 2px solid #ccc !important;
  background-color: #fff !important;
  color: #333 !important;
  text-align: center !important;
  text-decoration: none !important;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

div#increment-list-buttons {
  min-width: 200px !important;
}
.svara-bestall-slut-list-button {
  background: #b0b0b0 !important;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  border-radius: 10px;
  padding: 3px 8px;
  text-align: center;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
}
.svara-bestall-slut-list-button:hover {
  transition: transform 0.3s;
  transform: scale(1.3);
  font-size: 8px;
}
.svara-bestall-slut-list-button .svara-bestall-hovertooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1ea140;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
}
.svara-bestall-slut-list-button:hover .svara-bestall-hovertooltip {
  visibility: visible;
  transition: transform 0.3s;
  opacity: 1;
  font-size: 8px;
}

.svara-bestall-slut {
  background: #b0b0b0 !important;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  border-radius: 10px;
  padding: 3px 8px;
  text-align: center;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
}
.svara-bestall-slut:hover {
  transition: transform 0.3s;
  transform: scale(1.3);
  font-size: 8px;
}
.svara-bestall-slut .svara-bestall-hovertooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1ea140;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
}
.svara-bestall-slut:hover .svara-bestall-hovertooltip {
  visibility: visible;
  transition: transform 0.3s;
  opacity: 1;
  font-size: 8px;
}
label#email-homepage-label {
  color: white;
}
input#email-homepage {
  color: white !important;
}
input#password-homepage {
  color: white !important;
}
label#password-homepage-label {
  color: white !important;
}
.mainpage-btn {
  font-weight: bolder !important;
  font-size: 14px !important;
}
.mainpage-input {
  color: white !important;
  border-color: white !important;
  font-weight: bolder !important;
}

@media screen and (min-width: 1810px) {
  h3#Text-Fix-Tom-Varukorg {
    visibility: visible;
    font-size: 14px;
  }
}
@media screen and (max-width: 1810px) {
  h3#Text-Fix-Tom-Varukorg {
    visibility: hidden;
    font-size: 1px;
  }
}

.justify-tom-varukorg {
  justify-content: right;
  align-items: right;
  text-align: right;
  padding-right: 25px;
}
.tom-varukorg {
  text-align: center;
  justify-content: center;
}
.tom-varukorg-icon {
  margin: auto;
  margin-bottom: 16px;
  background-color: #f5f9f9;
  border-radius: 50px;
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
button.minicart-go-button.btn.btn-primary {
  border-radius: 10px !important;
  border-color: #1a512c !important;
  color: #1a512c !important;
  background-color: white !important;
  text-transform: capitalize;
  font-size: 17px !important;
  font-weight: bolder !important;
}
.cart-row-said {
  justify-content: center;
}
@media screen and (min-width: 768px) {

  p.my-3.new-cart-summary-small-text {
    font-size: 12px !important;
  }
  .Qty.col-lg-4.col-md-6 {
    padding: 0px !important;
  }
  span.priceDefault-cart-mininew {
    padding-left: 0px;
  }
}
.save-list-cart-button-new {
  justify-content: center;
  text-align: center;
  margin: auto;
}
.antal-artiklar-col {
  justify-content: center;
  text-align: center;
  max-width: 70% !important;
  margin: auto;
}
.OrdRef-class {
  padding-top: 15px !important;
  max-width: 70% !important;
  margin: auto;
}
.alla-listor-parapgraph.mt-2.center {
  padding-bottom: 20px;
  text-align: center;
}
@media screen and (min-width: 1100px) {
  .cart-latest-said {
    justify-content: center;
  }
  .listWprnewSaid.col {
    justify-content: center;
    max-width: 800px;
  }
  .alla-listor-section {
    justify-content: center !important;
    /*  max-width: 800px!important; */
    margin: auto;
    text-align: center !important;
  }
  .alla-listor-parapgraph.mt-2.center {
    padding-bottom: 20px;
    text-align: center;
  }
}
.alla-listor-section.row {
  max-width: 800px !important;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1200px) {
  .cart-latest-said {
    justify-content: center;
  }

  div#increment-list-buttons {
    min-width: 200px !important;
  }
  .antal-vikt-list-css-2.my-2.col-md-6.col-6 {
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
  }
}
div#tom-varukorg-button {
  text-align: center;
}
div#spara-alla-button {
  text-align: center;
}

.svara-bestall-cartmini {
  background: #ff0000 !important;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  border-radius: 10px;
  padding: 3px 8px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 5px;
}
.svara-bestall-cartmini:hover {
  transition: transform 0.3s;
  transform: scale(1.3);
  font-size: 8px;
}
.svara-bestall-cartmini .svara-bestall-cartmini-hovertooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1ea140;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
}
.svara-bestall-cartmini:hover .svara-bestall-cartmini-hovertooltip {
  visibility: visible;
  transition: transform 0.3s;
  opacity: 1;
  font-size: 8px;
}

.red-make-it {
  color: red;
  font-weight: bolder;
}
button.tombutton.btn.btn-primary {
  color: black !important;
  background-color: white !important;
  text-transform: capitalize;
  font-size: 10px !important;
  font-weight: bolder !important;
}
@media screen and (min-width: 1400px) {
  .cart-latest-said {
    justify-content: center;
  }
  .antal-vikt-list-css-2.my-2.col-md-6.col-6 {
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
  }
}
@media screen and (min-width: 960px) {
  .antal-vikt-list-css-2.my-2.col-md-6.col-6 {
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
  }

  div#increment-list-buttons {
    min-width: 200px !important;
  }
  .px-md-0.text-md-center.my-3.col-lg-3.col-md-3 {
    text-align: center !important;
    justify-content: center !important;
    margin: auto;
  }
}
.new-input-field {
  justify-content: center;
  text-align: center;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .cart-line-product-actions.text-right.my-2.text-sm-center.col-lg-1.col-md-1 {
    text-align: center !important;
    padding-top: 10px !important;
  }

  .urgent-message-holder {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px !important;
    background: #ffc107;
    color: #000;
    display: flex;
    font-weight: bolder;
    flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      text-align: center;
      font-size: 0.5rem !important;
  }
  .form-control.incrementText-minicart {
    min-width: 80px !important;
  }
  .product-line-info-mini a.product_name {
    line-height: 20px;
    display: block;
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px !important;
    color: #253237;
    text-align: left;
  }
  .Qty-minicart-new.col-lg-3.col-md-3 {
    padding-right: 0px !important;
  }
  p.my-3.new-cart-summary-small-text {
    text-align: center;
  }
  .px-0.col-md-6.col-6 {
    text-align: center;
  }

  .ProdBannerBg,
  .InfoBannerBg {
    overflow: hidden;
    background-blend-mode: difference;
    height: 150px !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    background-color: white;
    opacity: 0.7 !important;
  }
  .BannerHeadText {
    position: absolute;
    top: 20px !important;
    font-size: 50px;
    font-weight: bolder;
    color: #000000;
    text-align: center;
    left: 0;
    right: 0;
  }
  .BannerHeadText-withoutimg {
    position: absolute;
    top: 30px !important;
    font-size: 20px !important;
    color: #000;
    text-align: center;
    left: 0;
    right: 0;
  }
  li#hide-this-cmon-ineedtogo {
    display: none;
  }
  .detailText-withoutimg {
    position: absolute !important;
    top: 60px !important;
    left: 0 !important;
    right: 0 !important;
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    font-family: Akkurat, sans-serif, "Open Sans", Helvetica, Arial, sans-serif !important;
    line-height: normal;
    text-align: center;
  }
  p.my-3.new-cart-summary-small-text {
    font-size: 12px;
  }
  .Qty-minicart-new.col-lg-2.col-md-2 {
    padding-left: 0px !important;
    padding-top: 5px !important;
    padding-right: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .antal-vikt-list-css-2.my-2.col-md-6.col-6 {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .said-list-button-box {
    text-align: center !important;
  }
  .image-list-picture-box {
    justify-content: center !important;
    text-align: center !important;
  }
  .product-line-info-mini a.product_name {
    line-height: 20px;
    display: block;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 13px;
    color: #253237;
    text-align: center !important;
  }
  .minicart-listgroup-new.list-group.list-group-horizontal {
    text-align: center !important;
    justify-content: center !important;
    width: 100% !important;
    padding-top: 12px !important;
  }
  .product-line-info-mini a.product_name:hover {
    line-height: 20px;
    display: block;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 13px;
    color: #138b59;
    text-align: center !important;
    text-decoration: none !important;
  }
  .Qty-minicart-new {
    text-align: center;
    min-width: fit-content;
    padding-left: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 10px !important;
  }
  .said-list-picture {
    width: 100px;
    justify-content: center !important;
    text-align: center !important;
    display: block !important;
  }
}
.ProductLineInfoMiniCart.col-lg-4.col-md-4 {
  padding-right: 3px !important;
}

.CartMobView {
  justify-content: center !important;
}
.list-header-listeler {
  justify-content: center;
  text-align: center;
  font-family: Akkurat, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 20px;
  font-style: normal;
  font-display: swap;
}

.said-list-picture {
  max-width: 60%;
  margin: auto;
}
.cart-listitemincrement-first.list-group-item {
  padding: 11px !important;
  max-height: 43px !important;
}
.cart-listitemincrement-second.list-group-item {
  padding: 11px !important;
  max-height: 43px !important;
}

.adminpanelproductorderdetails {
  margin-top: 20px;
  overflow: auto;
  padding-left: 35px;
  padding-right: 35px;
  overflow: scroll;
  max-height: 800px;
}
.Cart-Wraper.container-fluid {
  max-width: 80%;
}
.adminpanel-modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.adminpanel-modal {
  width: 100%;
  height: fit-content;
  max-width: 300px;
  background-color: #a4d1c8;
  position: relative;
  border: 5px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 20px;
}

.antal-vikt-list-css {
  justify-content: center !important;
  margin: auto !important;
  align-items: center !important;
  text-align: center !important;
}

span.priceDefault {
  color: black;
}
.d-inline {
  display: inline !important;
  justify-content: center !important;
  text-align: center !important;
  margin: auto !important;
}
p.d-inline {
  display: inline !important;
  justify-content: center !important;
  text-align: center !important;
  margin: auto !important;
}
p.d-inline-cart {
  display: inline !important;
  justify-content: center !important;
  text-align: center !important;
  margin: auto !important;
  min-width: 60px;
  font-size: 10px;
}
.deleteOutlineIcn-all {
  color: rgb(0, 0, 0) !important;
}
.Total.\(tax.excl\.\).price.text-center.my-2.col-lg-1.col-md-6.col-sm-6.col-6 {
  text-align: center !important;
}

.List-wrapper-style {
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 20px;
  overflow: inherit;
  max-width: 80%;
  justify-content: center;
}
.MiniCartListItemOne.list-group-item {
  padding: 10px !important;
  padding-top: 7px !important;
  padding-right: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 7px !important;
}

.MiniCartListItemSecond.list-group-item {
  padding: 10px !important;
  padding-top: 7px !important;
  padding-right: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 7px !important;
}
.mini-cart.product-image.cartprodImg.media-middle.text-center {
  padding: 6px;
}
@media only screen and (max-width: 767px) {
  .mini-cart.product-image.cartprodImg.media-middle.text-center {
    max-width: 25%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 767px) {
  .ProductLineInfoMiniCart {
    text-align: center;
  }
  .list-button-fix-1 {
    padding-left: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .px-0.col-md-6.col-6 {
    text-align: center !important;
  }
  
  .minicart-listgroup-new.list-group.list-group-horizontal {
    padding-top: 14px !important;
  }
  .MiniCartListItemOne.list-group-item {
    min-width: 24px !important;
    max-width: 24px !important;
  }

  .MiniCartListItemSecond.list-group-item {
    min-width: 24px !important;
    max-width: 24px !important;
  }
  .product-line-info-mini a.product_name {
    line-height: 20px;
    display: block;
    overflow: hidden;
    font-size: 12px !important;
    font-weight: 400;
    margin-bottom: 13px !important;
    color: #253237;
    text-align: center !important;
  }
  .ProductLineInfoMiniCart.col-lg-4.col-md-4 {
    padding-left: 0px !important;
  }
  p.mini-cart-p-text {
    margin-bottom: 6px !important;
  }
  .Qty-minicart-new.col-lg-3.col-md-3 {
    padding: 0px !important;
    text-align: center !important;
  }
  .list-button-fix-1 {
    padding-left: 15px !important;
  }
}
@font-face {
  font-family: Akkurat, sans-serif;
  src: url("Akkurat.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  /* font-family: "Open Sans",sans-serif; */
  font-family: Akkurat, sans-serif;
  font-size: 14px;
  color: #222;
}
body ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
body ::-webkit-scrollbar-thumb {
  background-color: #1ea140;
}
a {
  text-decoration: none;
}
.zIndex0 {
  z-index: 0;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
/*TopNAv css*/
.TopNav {
  background: #1a512c !important;
}
.TopNav .bg-dark {
  /* background: #F50057 !important; */
  background: #1a512c !important;
  padding: 0.3rem 35px;
  color: #fcf7fb;
}
.TopNav .navbar-dark .navbar-nav .nav-link {
  color: #fcf7fb;
  font-size: 14px;
}
.verticalDivider {
  width: 1px;
  background: #fcf7fb;
  margin-left: 10px;
  margin-right: 10px;
}
.TopNav .MuiSvgIcon-root {
  font-size: 1rem;
}

.dollarIcn,
.langIcn {
  margin-top: 13px;
}
.listIcn {
  margin-top: 10px;
  margin-left: 10px;
}
.MenuWp .bg-dark {
  /* padding:0 35px; 
 height:58px; */
  padding: 8.5px 35px;
}
.MenuWp .navbar-brand {
  display: inherit;
}
/* .categoryNavWp{ display:inherit; background-color: #1ea140!important; padding:3px; width:200px;} */
.categoryNavWp .MuiSvgIcon-root.listIcn {
  color: #fff;
}
.categoryNavWp .nav-link {
  color: #fff !important;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 600;
  padding-right: 50px;
}
.categoryNavWp .MuiFormControl-root {
  width: 100% !important;
}

select#exampleSelect.inputselectWpr.form-select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 90%;
  min-height: 33px;
  padding: 0 2em 0 0.4em;
  font-size: 13.4px;
  background: url(caretdown.svg) no-repeat right 0.8em center / 1.4em,
    linear-gradient(
      to left,
      rgba(255, 255, 255, 0.3) 3em,
      rgba(255, 255, 255, 0.2) 3em
    );
  color: white;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-top: 10px;
  /* <option> colors */
}

option.optionWp {
  color: inherit;
  background-color: #138b59;
}

.homeTab {
  padding-left: 5px !important;
}
.MenuWp {
  background: #fff !important;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}
.MenuWp .bg-dark {
  /* background: #e1f0c5 !important; */
  background: #fff !important;
}
.MenuWp .navbar-dark .navbar-nav .nav-link {
  color: #222222;
  font-weight: 600;
  font-size: 14px;
  padding-left: 23px;
  padding-right: 10px;
  padding-top: 5px;
}
.MenuWp .navbar-dark .navbar-nav .nav-link a {
  color: #222;
}
.MenuWp #collasible-nav-dropdown {
  margin-top: 6px;
}
.MenuWp .navbar-dark .navbar-nav .nav-link:hover,
.MenuWp .navbar-dark .navbar-nav .nav-link:focus,
.MenuWp .navbar-dark .navbar-nav .nav-link:active {
  color: #1ea140;
  outline: none !important;
}
.SelectClass {
  /* overflow: scroll;  */
  font-weight: 900;
  font-style: normal;
  display: inline-block;
  /* width: 30%; */
  padding-inline: 16px 10px;
  /* padding-block: 7px; */
  border: none !important;
  background: none !important;
  max-height: "28px";
  overflow-y: "auto";
}
.SelectGreenClass {
  /* overflow: scroll;  */
  font-weight: 900;
  color: #1ea140;
  font-style: normal;
  display: inline-block;
  /* width: 30%; */
  padding-inline: 16px 10px;
  /* padding-block: 7px; */
  border: none !important;
  background: none !important;
  max-height: "28px";
  overflow-y: "auto";
}
.SelectClassFilter {
  /* overflow: scroll;  */
  font-weight: 900;
  font-style: normal;
  display: inline-block;
  /* width: 30%; */
  color: #28a745;
  padding-inline: 16px 10px;
  /* padding-block: 7px; */
  border: none !important;
  max-height: "28px";
  overflow-y: "auto";
}

.MenuWp .navbar-dark .navbar-nav .show > .nav-link,
.MenuWp .navbar-dark .navbar-nav .active > .nav-link,
.MenuWp .navbar-dark .navbar-nav .nav-link.show,
.MenuWp .navbar-dark .navbar-nav .nav-link.active {
  color: #1ea140 !important;
}
.MenuWp .dropdown-item.active {
  background: #1ea140 !important;
}
.MenuWp .dropdown-item:hover {
  background: #1ea140 !important;
  color: #fff;
}
.MenuWp .dropdown-menu.show {
  padding: 0;
}

/* .MyaccntBtn:hover{ color:#1ea140!important; background:#fff!important; border:none!important;} */
.TopNav .MuiList-root {
  display: flex !important;
}
.TopNav .MuiListItem-root {
  width: auto !important;
  align-items: inherit;
  text-align: inherit;
  align-items: initial !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.TopNav .MuiListItem-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.MuiTypography-colorTextSecondary {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold !important;
}
@media screen and (max-width: 991px) {
  span.svara-bestall-overimage {
    position: absolute;
    color: #1a512c;
    text-align: center;
    max-width: 80%;
    left: 10%;
    right: 10%;
    top: 25%;
    font-size: 12px !important;
    font-weight: 800;
    z-index: 10;
  }
  .DayPicker-Day {
    display: table-cell;
    font-size: 20px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }
  #div.DayPicker-Caption {
    font-size: 60px !important;
  }
  button#searchbutton-valj {
    font-size: 12px;
    padding: 4px;
  }
  .btn.saveBtn-spara-alla {
    background: #1a512c !important;
    color: #fff !important;
    padding-left: 15px !important;
    font-size: 10px !important;
    border: 1px solid #1a512c !important;
  }
}
.MuiTypography-displayBlock {
  font-size: 12px !important;
}
.ListImgWp {
  height: 115px;
  text-align: center;
}
.navbar-nav .MuiSvgIcon-root {
  color: #fff;
}
.CartPriceText {
  font-weight: bold !important;
  cursor: pointer;
}
.cartIcnList .MuiListItemAvatar-root {
  min-width: 40px !important;
}
/*Dashboard css start*/
.container-fluid.DashboardContainerWp {
  padding-left: 0;
  padding-right: 0;
}
/*Dashboard css end*/
/*Banner css start*/
.wrapper-banner-wp {
  position: relative;
  overflow: hidden;
  height: 40%;
  max-height: 500px !important;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.bannerWp {
  /*background:#ececec;*/
}
.bannerWp h1 {
  color: #222;
}
.BannerCol {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/*Banner Css ens */
/*ListingImg css start */
.ListingWp h4 {
  font-size: 14px;
  text-align: center;
}
/*ListingImg css End */
.mainHeadingText {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}
.prodImgWp {
  /* height:150px; */
  margin: 0 auto;
}
.prodListImgWpr.prodImgWp {
  width: 60%;
}
.TopSellimgWp {
  margin-top: 30px;
}
.TopSellimgWp .titleTxt {
  font-size: 14px;
  color: #222;
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
}
.TopSellimgWp .greenText {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.TopSellimgWp .MuiBox-root {
  text-align: center;
  font-size: 1.2rem !important;
}
.TopSellimgWp .MuiRating-root {
  text-align: center;
  font-size: 1.2rem !important;
}
.TopSellimgWp .card-text {
  margin-bottom: 5px;
}
.bkggrey {
  /* background:#fbfaf8; */
  width: 100%;
  position: relative;
  /* height:200px; */
}
.mainHedingCol {
  margin-bottom: 30px;
}
.IncrementListItemWpr .list-group-item {
  padding: 2px 15px !important;
  font-size: 20px;
}
.TopSellimgWp .newSpan {
  background: red !important;
  color: #fff;
  font-weight: normal;
  font-size: 9px;
  padding: 3px 7px;
  position: absolute;
  left: -16px;
  top: -10px;
}

/*Top selling Products*/
/*Shop Now css*/
.container-fluid.ShopNowContainerWp {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px;
}

.referansnummer-div {
  max-width: 70%;
  min-width: 200px;
  align-self: flex-end !important;
  margin-right: 20px;
  min-height: 20px;
}
input#referansnummer {
  min-height: 30px;
  padding-left: 5px !important;
}

/*Top Selling Products css */
/*Deal Of the Day css start*/
.container-fluid.DealOftheDayWp {
  background: #1ea140;
  padding: 50px 100px;
}
.DealCardImgCol {
  position: relative;
}
.DealImgWp {
  height: 170px;
  width: 100%;
  vertical-align: middle;
  padding-top: 30px;
}
.DealOftheDayWp .greenText {
  color: #000000;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}
.DealImgWp img {
  width: 100%;
}
.DealOftheDayWp .TitleText {
  text-align: left;
  margin-bottom: 15px;
}
.oldprice {
  color: #aaaaa8;
  padding-left: 10px;
}
.avlblTxt {
  color: #808080;
  font-size: 14px;
  font-weight: 600;
}
.avlblTxt span {
  color: #222;
  font-weight: 600;
}
.SaveText {
  color: #808080;
  font-size: 14px;
  font-weight: 600;
}
.progress {
  height: 0.5rem !important;
}
.addCartBtn {
  background: #efefef !important;
  color: #222 !important;
  border: 1px solid #efefef !important;
}
.viewBtn {
  background: #1a512c !important;
  color: #fff !important;
  border: 1px solid #1a512c !important;
}
.DealOftheDayWp .newSpan {
  background: #ffe2d0;
  color: #222;
  width: 40px;
  font-weight: 600;
  font-size: 10px;
  padding: 3px 8px;
  position: absolute;
  right: 30px;
  top: 10px;
}
.btn.saveBtn {
  background: #1a512c !important;
  color: #fff !important;
  padding-left: 15px !important;
  border: 1px solid #1a512c !important;
}
.btn.saveBtn-spara-alla {
  background: #1a512c !important;
  color: #fff !important;
  padding-left: 15px !important;
  border: 1px solid #1a512c !important;
}
.btn.skipBtn {
  width: 100%;
  font-size: 12px;
  background: #1ea140 !important;
  color: #fff !important;
  padding: 2px 10px 5px 10px;
  border: 1px solid #1a512c !important;
}
.btn {
  border-radius: 5px !important;
}
.close:focus {
  outline: none !important;
}
/*DealOF the day css end*/
/*Shop Now css Start*/
.smlHdText {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.headText {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
}
.ShopRow {
  padding-left: 100px;
  padding-right: 100px;
}
.ShopNowCol {
  background: #1ea140;
  padding: 30px;
  margin-bottom: 50px;
}
.shopBtn {
  background-color: #fff !important;
  color: #222 !important;
  padding-left: 10px;
  border-radius: 0 !important;
  border: 1px solid #fff !important;
  margin-top: 15px;
}
.ShopNwImg {
  width: 100%;
  height: 200px;
}
.ShopNwImg img {
  width: 100%;
  height: 100%;
}
.shopBtn.btn-primary {
  padding-left: 20px !important;
}
/*Shop Now css End*/
/*ClientTestimonail Css Start*/
.container-fluid.ClientTestimonialWp {
  background: #ececec;
  padding: 50px 100px;
}
.NameText {
  color: #222;
  text-align: center;
  font-size: 14px;
}
.testiText {
  color: #777777;
  margin-top: 15px;
  font-size: 14px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-bottom: 20px;
}
.clintTestiTitl {
  font-size: 16px;
  font-weight: 600;
}
.desigText {
  color: #777777;
  font-size: 12px;
}
.greenBox {
  width: 40px;
  height: 40px;
  background: #1ea140;
  position: absolute;
  top: 0;
  left: 15px;
  padding: 5px;
}
.greenBox .MuiSvgIcon-root {
  color: #fff;
  vertical-align: middle;
}
.clientCard {
  margin-left: 15px;
}
.clientHeadText {
  margin-bottom: 50px;
}
/*ClientTestimonial css end*/
/*DailyDiscountWp*/
.container-fluid.DailyDiscountWp {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px;
}
.DailyDiscountRow {
  background-color: #1ea140;
  background-image: linear-gradient(315deg, #1ea140 0%, #91baac 74%);
}
.DailyText {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}
input#Reference {
  min-height: 50px !important;
  padding-left: 20px !important;
}
.subtext {
  font-size: 40px;
  color: #fff;
}
.paraText {
  color: #fff;
  font-size: 14px;
}
.buyNowClass {
  background: #fff !important;
}
.IconWp {
  text-align: left;
}
.container-fluid.LatestBlogContainer {
  padding-left: 100px;
  padding-right: 100px;
}
.IconWp .MuiSvgIcon-root {
  color: #888888 !important;
  font-size: 1rem !important;
}
.IconWp span {
  font-size: 12px;
  color: #888888;
  font-weight: 600;
  padding-left: 5px;
}
.companyText {
  font-size: 16px;
  font-weight: 600;
}
/*footer css start*/
.footerCol {
  text-align: left;
}
.footerCol h5 {
  color: #fff !important;
}
.footerWp h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.footerWp .p_text {
  font-size: 12px;
}
.listWp {
  text-align: left;
}
.listWp li {
  font-size: 12px;
  margin-bottom: 10px;
}
.listWp li a {
  text-decoration: none;
}
.socialIconList {
  text-align: center;
}
.socialIconList li {
  margin-right: 15px;
  list-style: none;
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 32px;
  border-radius: 50%;
  background: #4f5768;
}
.socialIconList li .MuiSvgIcon-root {
  color: #fff;
}
.logoClass {
  display: inherit;
  margin: 0 auto;
}
.footerWp {
  background: #1a512c;
  border-top: 15px solid #5bec7f;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footerWp .MuiSvgIcon-root {
  margin-right: 10px;
  font-size: 1rem;
}
.footerWp .MuiSvgIcon-root.forwrdArrow {
  font-size: 0.8rem !important;
}
.textFooterNew {
  color: #ffffff !important;
}
.textFooterNew a {
  color: #ffffff !important;
}
.talkText {
  font-size: 14px;
  text-align: left;
}
.NumberText {
  font-size: 14px;
  text-align: left;
}
.footerLastRow .MuiSvgIcon-root {
  font-size: 2rem !important;
  color: #1ea140;
}
.footrHdtxt {
  font-size: 16px;
}
.FooterSbText {
  font-size: 14px;
  font-weight: 400;
}
.borderWp {
  width: 2px;
  background: #1ea140;
  height: 50px;
  position: absolute;
  right: 50px;
}
.footerTopRow {
  background: #646464;
  padding: 35px 25px 30px;
}
.joinText {
  color: #fff;
  font-size: 20px;
}
.joinText .MuiSvgIcon-root {
  font-size: 2rem !important;
  color: #fff;
}
.varietyText {
  color: #fff;
  font-size: 14px;
}
.phIcn {
  color: #1ea140;
  font-size: 2rem !important;
  padding-top: 10px;
}
.footerCol.InfoCol {
  padding-left: 60px;
}
/*Footer css end*/
/*Sidebar css start*/
/* .container-fluid{ overflow: hidden;} */
.product-quantity {
  position: absolute;
  top: 5px;
  left: 5px;
  min-width: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  background: #1ea140 !important;
}
.sidebar {
  background: white !important;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  position: sticky;
  top: 165px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;
}
.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}
.side-menu {
  border-top: 2px solid #21a73f;
}
.sidebar-header h3 {
  color: #222;
  padding: 1em 1em 0.1em;
}
.sidebar ul p {
  color: #fff;
  padding: 10px;
}
.sidebar .menu-open:hover {
  color: #fff;
}
.menu-open a:hover {
  color: #fff !important;
}
.sidebar .nav-item:hover {
  background: #e1f0c5 !important;
  color: #222;
  outline: none !important;
}
.sidebar .nav-item {
  padding-left: 25px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.sidebar .nav-item a:hover {
  color: #222 !important;
}
.sideNv .nav-item .nav-link {
  color: #000 !important;
  white-space: break-spaces;
  padding: 0.5rem 1.6rem 0.5rem 1rem;
  font-weight: bolder !important;
}
/* .items-menu {background: #ececec!important;color: #fff;} */
.sidebar .dropdown-toggle:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105" !important;
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 30px !important;
  top: 15px;
  border: none !important;
}
.sidebar .menu-open.nav-item .topDropdown.dropdown-toggle:after {
  transform: rotate(90deg);
  right: 17px !important;
  top: 10px !important;
}
.dropdown-toggle {
  position: relative;
}
li a.dropdown-toggle::after {
  position: absolute;
  right: 25px;
}
/* li a.dropdown-toggle::after { top:20px!important;} */
.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu::-webkit-scrollbar {
  width: 8px;
}
/* .side-menu::-webkit-scrollbar-thumb { background: #fff; border-radius: 3px;}
.side-menu::-webkit-scrollbar-thumb:hover {background: #fff;} */
.sidebar .nav-link {
  text-align: left;
  padding: 10px;
}
.sidebar .logowp {
  text-align: center;
  margin: 0 auto;
  width: 75%;
}
.sidebar .logowp img {
  max-height: 100px;
}
.sidebar .nav-link {
  /*padding:.9rem 1rem;*/
  font-size: 12.7px;
  color: #222;
  padding: 13px 8px 4px 4px !important;
  text-transform: uppercase;
}
/* .side-menu ul li :hover{ background:#1846ae; color:#fff;} */
.sidebar .nav-link .fa-random,
.nav-link .fa-handshake {
  font-size: 20px;
}

/* .sidebar .sideNv .nav-link:focus { background:#f5f5f5!important; color:#ececec!important; } */
.sidebar .setActiveTab,
.sidebar .setActiveTab .nav-link {
  color: #1ea140 !important;
}
.ucLogo {
  margin: auto;
  justify-content: center;
  text-align: center;
  color: white;
}
img.mb-2.logoClassUc {
  max-width: 160px;
}
.sidebar .nav-link .fa-copy {
  font-size: 20px;
  margin-right: 20px !important;
  margin-left: 5px;
}
.sidebar .logowp img {
  width: 100%;
}
.sidebar .dropdown-toggle.nav-link .fa-file-alt {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 15px !important;
}
/*Lesson Sidebar*/
.sidebar .headTitle h3 {
  font-size: 14px;
  font-weight: 500;
  color: #bebdbd;
}
.videoTime {
  color: #bebdbd;
}
.sidebar .menu-open.nav-item {
  background: #e1f0c5;
}
/* .sidebar .items-menu .menu-open.Submenuopen.nav-item a:hover,
.sidebar .items-menu .menu-open.Submenuopen.nav-item:hover a,
.sidebar .items-menu .menu-open.Submenuopen.nav-item:hover{
 color: #1ea140 !important;
 background: #fff !important;
} */
.sidebar .items-menu .nav-link {
  text-transform: capitalize;
  font-family: Akkurat, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  padding: 10px 30px 10px 15px;
}
.sidebar .items-menu:hover,
.sidebar .items-menu:active,
.sidebar .items-menu.navbar-collapse,
.sidebar .items-menu.navbar-collapse .nav-item,
.sidebar .menu-open.nav-item .nav-link:hover .items-menu {
  background: #fff !important;
}
.sidebar .items-menu .nav-item:hover,
.sidebar .items-menu .nav-item a:hover {
  background: #fff !important;
  color: #1ea140 !important;
}
/* .sidebar .menu-open.Submenuopen.nav-item a:hover .items-menu.submenucollpse .nav-item {background: #fff !important;}
.sidebar .menu-open.Submenuopen.nav-item a:hover .items-menu.submenucollpse .nav-item a{color:#222 !important;}
.sidebar .menu-open.Submenuopen.nav-item a:hover .items-menu.submenucollpse .nav-item a:hover{color:#1ea140!important;} */
.sidebar .menu-open.nav-item .dropdown-toggle::after {
  top: 10px;
}
.sidebar .items-menu.submenucollpse .nav-item {
  padding-left: 35px;
}
.sidebar .items-menu .nav-link.dropdown-toggle:after {
  top: 10px;
}
/*ProductDetail Page css start*/
.productDeatilWp {
  width: 100%;
}
.productDeatilWp img {
  width: 100%;
}
.container-fluid.ProductDetailWp {
  padding-left: 35px;
  padding-right: 35px;
}
/* .col-md-3.ProdsidebarWp{ padding-right:0;} */
.col-md-5.productDetailContentWp {
  padding-left: 0;
}
.IngredientText {
  font-size: 14px;
  color: #222;
}
.IngredientText span {
  font-weight: 600;
}
.RefNo {
  font-size: 12px;
}
.validTxt {
  font-size: 14px;
}
.RefNo,
.validTxt {
  color: #888;
  font-weight: 400;
}
.ProdNameTxt {
  font-size: 16px;
  color: #222;
}
.MainHdrTxt {
  font-size: 24px;
  color: #1ea140;
  line-height: 1.5;
}
.CompriceTxt {
  color: #222;
  font-size: 12px;
}
.btnAddShop {
  background: #1a512c !important;
  color: #fff !important;
  font-size: 12px !important;
  text-decoration: none !important;
}

.said-product-overview-list {
  color: #fff !important;
  font-size: 12px !important;
  text-decoration: none !important;
  border-color: none !important;
  font-weight: bold !important;
}

#button-product-overview {
  border-radius: 10px !important;
  min-width: 90%;
}

#button-under-product-list-overview {
  text-align: center !important;
}

#product-overview-card-body-adjustment {
  padding-bottom: 0px !important;
}

.ListWp > .MuiList-root {
  display: flex !important;
}
.MuiListItem-root {
  padding: 0 !important;
  width: auto !important;
}
.MuiList-root {
  display: flex;
}
.MuiAccordionDetails-root .MuiTypography-body1.MuiTypography-root {
  width: 100% !important;
}
.prodwghttext {
  font-size: 14px;
}
.pricespan {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.ProductDetailColWp .pricespan {
  font-size: 36px;
}
.ProductDetailColWp .Compartiveprice {
  font-size: 12px;
}
.ProductOverview .card-text .ProductDetailColWp .priceValidText {
  font-size: 12px;
}
.proddetailSubText {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2px;
}
/*ProductDetailPage css end*/
/*Product OverviewPage*/
.ProductOverview.container-fluid,
.InfoWrap.container-fluid {
  padding-left: 35px;
  padding-right: 35px;
}
.ProdBannerBg,
.InfoBannerBg {
  overflow: hidden;
  background-blend-mode: difference;
  height: 250px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  opacity: 0.7;
}
.ProdBannerBg img {
  width: 100%;
}
.BannerWithoutImg {
  text-align: center;
  background-color: #fff;
  padding: 10px 0;
}
.BannerWithoutImgHeadText {
  font-size: 50px;
  color: #000;
  text-align: center;
  left: 0;
  right: 0;
  font-weight: 600;
}
.BannerWithoutImgdetailText {
  padding: 0 40px;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  font-family: Akkurat, sans-serif, "Open Sans", Helvetica, Arial, sans-serif !important;
  line-height: normal;
  text-align: center;
}
.BannerHeadText {
  position: absolute;
  top: 65px;
  font-size: 50px;
  font-weight: bolder;
  color: #000000;
  text-align: center;
  left: 0;
  right: 0;
}
.BannerHeadText-withoutimg {
  position: absolute;
  top: 65px;
  font-size: 50px;
  font-weight: bolder;
  color: #000;
  text-align: center;
  left: 0;
  right: 0;
}
.InfoBannerHeadText {
  position: absolute;
  top: 40%;
  font-size: 60px;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  /*   font-family: "Zooja" !important; */
}
.Proditemcount,
.CountWithoutImg {
  font-size: 30px;
  font-weight: normal !important;
}
@media (min-width: 992px) {
  /* .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    min-width: fit-content;
} */
}
.detailText {
  position: absolute;
  top: 120px !important;
  left: 0;
  right: 0;
  padding: 20px 40px;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  font-family: Akkurat, sans-serif, "Open Sans", Helvetica, Arial, sans-serif !important;
  line-height: normal;
  text-align: center;
}
.detailText-withoutimg {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  padding: 20px 40px;
  color: #000;
  font-weight: 400;
  font-size: 22px;
  font-family: Akkurat, sans-serif, "Open Sans", Helvetica, Arial, sans-serif !important;
  line-height: normal;
  text-align: center;
}
.ProductOverview .titleTxt {
  /* font-size: 16px; color:#222;text-align:center ; font-weight:600; margin-bottom: 10px; */
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #231f20;
  display: block;
  width: 100%;
  height: 45px;
  overflow: scroll;
  font-family: Akkurat, sans-serif;

  /* height:48px; */
}
.ProductOverview .MuiBox-root {
  text-align: left;
}
.ProductOverview .MuiRating-root {
  font-size: 1rem;
}
.ProductOverview .greenText {
  color: #000000;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
}
SavetoListSpan .ProductOverview .card-text {
  margin-bottom: 3px !important;
}
h2.nyheter-h2 {
  color: aquamarine;
}
.ProductOverview .BestDealSpan {
  background: #f50057 !important;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  padding: 5px 10px;
  position: absolute;
  left: -1px;
  top: 0px;
}
.SavetoListSpan {
  background: #f0efef !important;
  color: #7e7d7d;
  font-size: 15x;
  padding: 7px 7px;
  border-radius: 20px;
  position: absolute;
  right: 25px;
  top: 15px;
}
.SavetoListSpan:hover {
  background: #f0efef !important;
  color: rgb(255, 0, 0);
  font-size: 12px;
  padding: 7px 7px;
  border-radius: 20px;
  transition: 0.5s;
  position: absolute;
  right: 25px;
  cursor: pointer;
  top: 10px;
}
.ProductOverview .SavetoListSpan {
  background: #f0efef !important;
  color: #7e7d7d;
  font-size: 8px;
  padding: 7px 7px;
  border-radius: 20px;
  position: absolute;
  right: 10px;
  top: 0px;
}
.ProductOverview .SavetoListSpan:hover {
  background: #f0efef !important;
  color: rgb(255, 0, 0);
  font-size: 12px;
  padding: 7px 7px;
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: -4px;
}
.WeightText {
  font-size: 14px;
  color: #808080;
  text-align: left;
  font-weight: 600;
  padding-top: 5px;
}
.OldText {
  font-size: 12px;
  color: #bfb8b8;
  padding-left: 10px;
}
.DiscountText {
  padding-left: 15px;
  font-size: 12px;
  color: #1ea140;
}
.ProductOverview .bkggrey {
  height: auto;
}
.ProductOverview .prodImgWp {
  /* max-height: 120px; height:120px;  */
  width: 50%;
  margin-top: 15px;
}
/* .prodImgWp img{ height:100%;} */
.ClubImgWpr.card-img-top {
  max-height: 300px;
  overflow: hidden;
}
.ProductOverview .card-body {
  padding: 0.8rem !important;
}
.FilterText {
  font-size: 14px;
  color: #222;
  text-align: left;
}
.ClearText {
  font-size: 14px;
  color: #1ea140;
  text-align: left;
  cursor: pointer;
}
.ClearIcon {
  /* font-size: 1px; */
  color: #1ea140;
  /* text-align: left; */
  cursor: pointer;
}
div[name="ddButtonChangeColor"] button {
  color: #1ea140 !important;
}
a[data-clear="true"] {
  color: #1ea140;
}
a[data-clear="true"]:hover {
  color: #1ea140;
}
.ProductOverview .MuiFormControl-root {
  /*min-width:155px;*/
  margin: 0;
  width: 100%;
}
.MyaccntBtn.linkclass a {
  color: #1ea140 !important;
}
.MainHeadingText {
  font-size: 18px;
  text-align: center;
}
/* .items-menu.collapse .menu-open.nav-item{ padding-left:40px;  } */
/* .ProductOverview .MuiPopover-paper{top:345px!important; } */

/* .btnLoadMoreProduct{ border:1px solid #1ea140;background:#fff!important;color:#1ea140; }
.btnLoadMoreProduct:hover{ border:1px solid #1ea140;background:#fff!important;color:#1ea140;}
.btnLoadMoreProduct:active{ color:#1ea140;}
.btnLoadMoreProduct:focus{color:#1ea140; }
.btnLoadMoreProduct.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btnLoadMoreProduct.btn-outline-secondary:not(:disabled):not(.disabled).active, 
.btnLoadMoreProduct.show > .btnLoadMoreProduct.btn-outline-secondary.dropdown-toggle{ color:#1ea140!important; } */

/*Contact us css*/
.input-group-text {
  padding: 0.24rem 0.75rem !important;
}
.ContactUsWp {
  margin: auto !important;
  padding: 20px !important;
}
.conatctUSCard {
  padding: 20px;
}
.storeDetailText {
  font-size: 14px;
  color: #222;
  text-align: center;
}
.storeDetailText a {
  color: #1ea140;
}
.FormheadText {
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 30px;
  text-transform: capitalize;
}
.SelectGrid .MuiFormControl-root {
  width: 100% !important;
}
.SelectGrid textarea {
  width: 100% !important;
}
.MsgHdText {
  color: #222;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.BtnPrimaryContact {
  background-color: #1ea140 !important;
  color: #fff !important;
}
.ContactUsWp .MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 25px !important;
}
.ContactUsWp .MuiInputLabel-outlined {
  font-size: 14px;
}
.StepsHeadtxt {
  color: #222;
  font-size: 16px;
  text-align: left;
}
.corporateCard {
  padding: 20px;
}
.ContactUsWp .MuiTypography-root {
  font-size: 14px !important;
  color: #222;
}
.ContactUsWp p {
  font-size: 14px;
  text-align: left;
  color: #222;
}
.dzu-inputLabel {
  color: #1ea140 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1ea140 !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #1ea140 !important;
}
.signWp {
  margin-bottom: 30px;
}
/*Contact us Css End*/
/*Login css start*/
.frmLbl {
  text-align: left;
}
.LoginContainer .MuiAvatar-circle,
.makeStyles-avatar-2 {
  background: #1ea140 !important;
}
.LoginContainer .MuiButton-containedPrimary {
  background: #1ea140 !important;
}
.MuiTypography-colorPrimary {
  color: #1ea140 !important;
}
.LoginContainer .makeStyles-paper {
  margin-top: 30px !important;
}
.LoginContainer .MuiCheckbox-colorPrimary.Mui-checked {
  color: #1ea140 !important;
}

.LoginContainer .MuiTypography-root {
  text-align: left;
  font-size: 12px;
  margin-bottom: 10px;
}
/* Forgot Pass CSS */
.forgot-card {
  font-family: cursive;
}
/*Cart PAge css start*/
.list-group-item {
  cursor: pointer;
  margin-bottom: 0px !important;
}
p#blog-modal-contentid {
  color: black !important;
  font-size: 15px !important;
  font-weight: 100 !important;
}
.product-line-info a.product_name {
  line-height: 20px;
  display: block;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 13px;
  color: #253237;
}

.svara-bestall-cart {
  background: #ff0000 !important;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  border-radius: 10px;
  padding: 3px 8px;
  text-align: center;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 25%;
}
.svara-bestall-cart:hover {
  transition: transform 0.3s;
  transform: scale(1.3);
  font-size: 8px;
}
.svara-bestall-cart .svara-bestall-cart-hovertooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1ea140;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
}
.svara-bestall-cart:hover .svara-bestall-cart-hovertooltip {
  visibility: visible;
  transition: transform 0.3s;
  opacity: 1;
  font-size: 8px;
}

.svara-bestall-detailpage {
  background: #b0b0b0 !important;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  border-radius: 10px;
  padding: 3px 8px;
  text-align: center;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
}
.svara-bestall-detailpage:hover {
  transition: transform 0.3s;
  transform: scale(1.3);
  font-size: 8px;
}
.svara-bestall-detailpage .svara-bestall-detailpage-hovertooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1ea140;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
}
.svara-bestall-detailpage:hover .svara-bestall-detailpage-hovertooltip {
  visibility: visible;
  transition: transform 0.3s;
  opacity: 1;
  font-size: 8px;
}

.svara-bestall {
  background: #b0b0b0 !important;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  border-radius: 10px;
  padding: 3px 8px;
  text-align: center;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 33%;
}
.svara-bestall:hover {
  transition: transform 0.3s;
  transform: scale(1.3);
  font-size: 8px;
}
.svara-bestall .svara-bestall-hovertooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1ea140;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
}
.svara-bestall:hover .svara-bestall-hovertooltip {
  visibility: visible;
  transition: transform 0.3s;
  opacity: 1;
  font-size: 8px;
}
h1.nyheter-nyheter.banner-header.text-center {
  color: cornsilk;
}
button#nyheter-button {
  border-color: aquamarine;
  color: aquamarine;
}
h3.beskrivning-text-sales {
  font-size: 15px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h4,
h5,
h6 {
  text-transform: capitalize;
  color: #222222;
  font-weight: 700;
  /* line-height: 24px; */
}
.two-buttons-for-savinganddelete.row {
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 20px !important;
  padding-right: 15%;
  padding-left: 15%;
  padding-bottom: 20px !important;
}
.product-price-and-shipping .price {
  color: #1ea140 !important;
  font-size: 16px;
}
.product-line-grid-left img {
  max-width: 100%;
  padding-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
}

.product-line-grid-left {
  padding-top: 20px;
}
p.din-varukorg-p-text {
  text-align: center;
  padding-top: 15px;
}
img {
  max-width: 100%;
  margin: auto;
  height: auto;
}
.card-block {
  color: #253237;
  text-align: center;
  font-size: 24px;
  line-height: 1;
  font-style: normal;
  text-transform: none;
  font-weight: 600;
}
.total-order-summary {
  position: sticky;
  bottom: -17px;
  background: #1a512c;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
  color: white;
}
.total-order-summary-bigcart {
  position: sticky !important;
  position: -webkit-sticky !important;
  bottom: 20px !important;
  background: #1a512c;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
  color: white;
  z-index: 1;
}
.mini-cart-modal-body.modal-body {
  overflow-x: hidden;
}
.CartItems {
  list-style-type: none !important;
  margin-left: 0;
  padding-left: 0;
}
.cartLeft.col-md-9 {
  padding-left: 0;
  padding-right: 0;
}
.container-fluid.Wraper {
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 20px;
  overflow: inherit;
}
.overview {
  margin-top: 30px;
}
.CartItems .list-group-item {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.CartItems .MuiSvgIcon-root {
  color: #222;
}
.MuiSvgIcon-root.ChekiconWhite {
  font-size: 1.2rem;
}
.CartItems li {
  border-bottom: 1px solid #e0e0e0;
}
.cart-summary-line .label,
.cart-summary .label,
.cart-summary-line.cart-summary-subtotals .label,
.cart-summary-line.cart-summary-subtotals .value {
  text-transform: capitalize;
  font-weight: 600;
}
.card-block .label {
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}
.card-block {
  padding-top: 30px;
}
.cart-summary-line {
  clear: both;
}
.cart-summary-line .label {
  padding-left: 0;
  font-weight: 400;
  white-space: inherit;
}
.cart-summary-line .value {
  color: #253237;
  font-weight: 600;
  font-size: 13px;
  text-align: end;
  /* float: right; */
}
.cart-summary-line {
  clear: both;
}
/*Order Confirmation css */
.ProductQty {
  font-size: 14px;
  color: #222;
  font-weight: 400;
}
.productweight {
  color: #222;
  font-size: 14px;
}
.orderDetailHeading {
  font-size: 14px;
  font-weight: 600;
  color: #222;
}
.Chekicon {
  color: #32a852;
}
.ProdListImgWp img {
  width: 100%;
}
.headingText {
  font-size: 14px;
  color: #222;
  font-weight: 600;
}
.ChekiconWhite {
  color: #fff;
  margin-right: 5px;
}
.ModaladdProdHeader {
  font-size: 18px;
}
.verticalLine {
  width: 1px !important;
  background: #bfbfbf;
  height: 150px;
}
.ClubTitle {
  color: #fff;
}
.clubBtn.btn-secondary {
  background: #fff !important;
  color: #32a852 !important;
  border: 1px solid #fff !important;
}
/* .ClubImgWpr{ height:310px;} */
.clubdetailImgwp {
  width: 100%;
  height: 400px;
}
.clubdetailImgwp img {
  width: 100%;
  height: 100%;
}
.InnerWp {
  background: rgba(33, 33, 33, 0.8);
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.bannerHdTxt {
  font-size: 28px;
  color: #fff;
}
.col.BannerTextWp {
  position: absolute;
  top: 20%;
  color: #fff;
}
.bannerHdTxt {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}
.CardclubDetail {
  height: 220px;
}
.SizeText {
  font-size: 14px;
  font-weight: 600;
}
.closeBtn {
  float: right;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
}
/*Related Products*/
.relatedProductCardWp {
  /* float: left; */
  display: inline-block !important;
  margin-bottom: 20px;
  width: 100%;
}
.relatedProductCardWp .priceText {
  color: #1ea140;
  font-weight: 600;
}
.relatedProductCardWp img {
  height: 140px;
}
.relatedProductCardWp .PriceText {
  color: #1ea140 !important;
  font-weight: 600;
}
.BrandName {
  color: #808080;
  font-size: 14px;
}
.SameCtegoryImg {
  height: 130px !important;
}
.NewProduct {
  position: absolute;
  top: 10px !important;
  left: 10px;
  display: inline-block;
  color: #fff;
  padding: 0 5px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: center;
  z-index: 1;
  background-color: #1ea140;
}
.DiscountedPrice {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  background: #cf292b;
  padding: 0 4px;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  z-index: 4;
  margin-left: 2px;
}
/*Saved List CSS*/
.hrLineClass {
  width: 100%;
  padding-left: 10px;
  padding-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  box-sizing: border-box;
}
.ListingClass .MuiFormControlLabel-root {
  padding-left: 30px;
  padding-top: 10px;
}
.ListingClass.ProductListWp {
  height: 300px;
  overflow-y: auto;
}
.ListingClass .MuiFormLabel-root {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: 0 !important;
  color: #fff !important;
  background-color: #1ea140 !important;
}
.AutoDeliveredText {
  font-size: 12px;
  padding-top: 10px;
}
.SubscribeListProducts {
  height: 110px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: red;
}
.lblClass {
  color: #222;
  font-size: 13px;
  font-weight: 600;
}
.Addsubscription {
  vertical-align: top;
  border: 1px solid #d5dbdb;
  text-align: center;
  height: 150px;
}
.CircularView {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 1px solid #d5dbdb;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-top: 15%;
}
.CircularPlus {
  font-size: 30px !important;
}
.plusIcon {
  vertical-align: middle;
  font-size: 2.5rem !important;
  color: #d5dbdb !important;
}
.addSubscriptionText {
  margin: 15px 0 0 0;
  padding: 0 10px 0 10px;
  font-size: 14px;
}
.addSubscriptionCol {
  margin: 30px auto 40px;
}
.SubscribeListProductsSlider {
  height: 120px !important;
}
.form-control.SlectWrp {
  height: 30px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.delivryText {
  padding-top: 13px;
}
.recomndText {
  font-size: 20px;
  margin-bottom: 50px;
}
.subscribrProducts .card-body {
  padding: 15px 10px;
}
.cardHead.active .PriceLbl {
  color: #fff !important;
}
.mapIcon {
  color: #1ea140;
  vertical-align: top;
  margin-top: 2px;
  margin-right: 5px;
}

/*ListAccordion css*/
/* .ListAccordion.accordnWp .card{ border-radius: 0;}
.ListAccordion.accordnWp .cardHead.active{ background-color: inherit!important; }
.ListAccordion.accordnWp .cardHead.active label.lbltxt{ color:#222!important;}
.ListAccordion.accordnWp .cardHead.active{ border:inherit;}
.ItemNameText{ font-size:#222; font-size: 16px; font-weight:600;}
.PriceText{ color:#1ea140; font-size:18px; font-weight: 600;}
.DescriptionText{ color:#808080!important; font-size: 14px!important; padding-top:5px;}
.ListAccordion.accordnWp .card{ border-bottom: 0!important;}
.ListAccordion.accordnWp .card:last-child{ border-bottom:1px solid rgba(0, 0, 0, 0.125)!important;}
.ListAccordion.accordnWp .card:first-child{ border-bottom:0!important;}
.ListAccordion.accordnWp .collapse.show{ border:inherit;}

.ListAccordion.accordnWp .MuiFormControlLabel-root{ vertical-align: -webkit-baseline-middle; } */
.RadioBtn .MuiSvgIcon-root {
  color: #1ea140;
}
.switchbutton .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #1ea140;
}
.switchbutton .MuiSwitch-colorSecondary.Mui-checked {
  color: #1ea140;
}
.WeekText {
  font-size: 16px;
  color: #222;
  font-weight: 600;
  padding-bottom: 10px;
}
.LablText {
  color: #222;
  font-weight: 600;
  font-size: 14px !important;
}
.modal-title {
  font-size: 16px !important;
  font-weight: 600;
}
/*Invoice css start*/

.invoice-address h3 {
  font-size: 30px;
  margin-bottom: 1rem;
}
.ms-panel-body.order-circle h6 {
  margin-bottom: 20px;
}
.invoice-address h5 {
  font-size: 16px;
  margin-bottom: 0.75rem;
}
.ms-panel {
  background: #fff;
  box-shadow: 0 1px 6px 1px rgb(69 65 78 / 10%);
  margin-bottom: 30px;
}
.ms-panel-header {
  position: relative;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.justify-content-between {
  justify-content: space-between !important;
}
.invoice-address h3 {
  font-size: 20px;
  margin-bottom: 1rem;
}
.d-flex {
  display: flex !important;
}
.DialogueWp .MuiList-root {
  display: block !important;
}
.DialogueWp .MuiDialog-paper {
  margin: 32px;
  position: relative;
  overflow-y: auto;
}
.DialogueWp .MuiListItem-gutters {
  padding: 5px 30px 5px 20px !important;
}
.DialogueWp .MuiList-padding {
  padding-bottom: 16px !important;
}
.DialogueWp .MuiTypography-root {
  font-size: 14px !important;
}
.addListIcon {
  color: #fff !important;
  font-size: 1rem !important;
}
.DialogueWp .MuiAvatar-root {
  width: 35px !important;
  height: 35px !important;
}
.DialogueWp .MuiTypography-h6 {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #222 !important;
}
.ModalWp .modal-content {
  width: 250px !important;
}
.ModalbodyClass.savetolist {
  max-height: 300px;
  overflow-y: scroll;
}
.modal-footer.savetolist {
  justify-content: flex-start;
}
.modal-footer.savetolist .createPlayListbtn {
  display: block;
  width: 100%;
  text-align: left;
}
.ModalbodyClass .MuiSvgIcon-root {
  color: #1ea140 !important;
}
.ModalbodyClass .MuiSvgIcon-root.Lockicn {
  color: #777777 !important;
  font-size: 1rem !important;
  vertical-align: initial !important;
  margin-left: 15px;
}
.MuiFormControlLabel-label {
  font-size: 14px !important;
}
.DialogueWp .MuiDialog-paper {
  position: absolute;
  top: 25%;
  left: 27%;
}
.ModalWp.modal {
  left: 0;
  top: 20% !important;
}
#cart-modal.modal-dialog {
  margin: 0px auto !important;
  margin-right: 0px !important;
}
div#cart-modal {
  /* min-width: 100px; */
  max-width: 650px;
}
.ProductLineInfoMiniCart {
  text-align: center !important;
}

.product-line-info-mini a.product_name {
  line-height: 20px;
  display: block;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 13px;
  color: #253237;
  text-align: left;
}
.product-line-info-mini a.product_name:hover {
  line-height: 20px;
  display: block;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 13px;
  color: #138b59;
  text-align: left;
  text-decoration: none !important;
}

.ListLbl {
  font-size: 14px;
  font-weight: 600;
}
.btn.createPlayListbtn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.PrintBtn {
  position: absolute;
  right: 15%;
  bottom: 22%;
}
.hide-on-print {
  border: 1px solid #1ea140 !important;
  background: #1ea140;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
}
.hide-on-print:focus {
  outline: none;
}
.AccordianWpr .MuiAccordion-root {
  border: 1px solid #f2f2f2;
}
body::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.spanPric {
  color: #000000;
  font-weight: 400;
  font-size: 11px;
}
/* .scheduleBtn{ margin-left:100px;} */
body::-webkit-scrollbar-thumb {
  background-color: #1ea140;
}

/*New css start*/
/* .css-2b097c-container{ z-index: 1000;} */
.dailydisImg {
  padding-top: 30px;
}
.price.text-center {
  padding-top: 10px;
}
.productDeatilWp {
  width: 85%;
  margin-top: 35px;
}
.orderconfList .price.text-center {
  padding-top: 0px;
}
.btnAddShop.text-left.cartright {
  margin-bottom: 20px;
}
/*Headercss*/
.heaerContainer {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
/*ClientTestimonal css start*/
.clintTestiTitl.card-title {
  text-align: center;
}
.desigText.card-subtitle {
  text-align: center;
}
/* .clientHeadText{ text-align: center;} */
/*DealoftheDaycss*/
.VisibilityIconClass {
  color: #fff !important;
}
/*DailyDiscount*/
.dailydisImg {
  text-align: center;
  margin-top: 30px;
}
/*Latestblog css start*/
.latestblogcol3 img {
  width: 100%;
}
.latestblogcol {
  padding-left: 0 !important;
}
.latestblogcol3 {
  padding-right: 0 !important;
}
.desktopNone {
  display: none;
}
.orderconfList .CartItems li {
  padding: 10px;
}
.MuiFormLabel-root.LablText {
  color: #222;
  font-weight: 600;
  font-size: 14px !important;
  font-family: inherit;
}
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.selectDte {
  margin-top: 5px;
  margin-bottom: 10px;
}
.react-datepicker__input-container input:focus,
.react-datepicker__input-container input:active {
  outline: none;
}
.MuiIconButton-colorPrimary {
  color: #1ea140 !important;
}
.MuiRadio-colorPrimary.Mui-checked {
  color: #1ea140 !important;
}
/*Latestblog css end*/
/*MultiList css start*/
.list-group .ListDeleteIcn {
  position: absolute;
  right: 15px;
  top: 20px;
}
.ProdovrlistWpr {
  padding-left: 0;
  padding-right: 0;
}
.AutoScheduleText h3 {
  text-align: center;
  font-size: 17px!;
  left: 0;
  right: 0;
  font-weight: bolder;
}
.antal-list-produkter-text {
  text-align: center;
}
.headerClass {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: sticky;
  top: 0;
  z-index: 2;
}
.MuiSelect-selectMenu {
  font-size: 14px !important;
}
.MuiInputBase-input {
  padding: 4px 0 !important;
}
.listWpr.col-md-4 {
  padding-right: 0 !important;
}
.prodordInfo {
  margin-top: 30px;
}
.ProdRowWp .list-group-item {
  padding: 0.2rem 1rem;
  border-radius: 0;
}
.form-control.InputincrementClass {
  text-align: center;
  border-left: none;
  border-right: none;
  border-radius: 0;
  height: auto;
}
.InputincrementClass:focus {
  outline: none;
}
.list-group.list-group-horizontal {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.topSellprodListwp {
  border: 1px solid #e0e0e0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* margin-bottom:20px; */
  border-left: 0;
  border-top: 0;
}
.topSellprodListwp .card {
  padding: 0.8rem;
}
.prodctListOverviewp .MuiMenuItem-root {
  font-size: 14px !important;
}
.priceValidText {
  min-height: 20px !important;
  font-size: 10px;
  text-align: center;
}
.MenuWp .searchWpr {
  width: 345px;
  padding-left: 10px;
  padding-right: 15px;
  margin-bottom: 20px;
}
.MenuWp .css-2b097c-container {
  width: 100%;
}
.MenuWp .col-md-6.SearchWprCol {
  width: 300px !important;
}
.MuiSvgIcon-root.shoppincrtIcn {
  color: #222 !important;
  font-weight: bolder !important;
}
.makeStyles-root-1 {
  background: inherit !important;
}
.PriceTag {
  font-size: 21px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.6px;
  color: #000000;
}
.PriceTag.card-title {
  margin-bottom: 5px !important;
}

button.accept-button-for-leverans.btn.btn-primary {
  background: #007bff !important;
  color: white !important;
}
.accept-leverans-container.modal-footer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.Notification-urgent {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: 15px;
  background: yellow;
  color: black;
  font-family: monospace;
  font-weight: bolder;
  text-align: center;
}
.urgent-message-holder {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5px !important;
  background: #ffc107;
  color: #000;
  display: flex;
  font-weight: bolder;
  flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-size: 0.5rem !important;
}
.Compartiveprice {
  font-size: 12px;
  color: #231f20;
  font-family: Akkurat, sans-serif, "Open Sans", Helvetica, Arial, sans-serif,
    Zooja Pro Regular;
  letter-spacing: 0.2px;
  line-height: 13px;
  text-align: center;
  clear: both;
}
.BnerTextCol {
  position: absolute;
  top: 20px;
}
.CommercialText {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  display: block;
  width: 100%;
  height: 28px;
  overflow: scroll;
  color: #1a1919;
}
.form-control.InputincrementClassProductOverview {
  border-left: none;
  border-radius: 0;
  border-right: none;
  min-width: 65px !important;
  height: auto;
  text-align: center;
}
.ListAddIcnList {
  margin: 5px;
  color: rgb(0, 0, 0);
}
.LoginModal .MuiOutlinedInput-input {
  padding: 25px 15px !important;
}
svg.MuiSvgIcon-root.save-icon-cart-summary {
  color: indianred;
}
/*Footer css start*/
.GKlogoWp {
  width: 90%;
  margin-bottom: 20px;
  margin: 0 auto;
  filter: brightness(100);
}
.GKlogoWp img {
  width: 100%;
}
/*Footer css End*/
.toplogowp {
  width: 250px;
  margin-right: 15px;
}
.toplogowp img {
  width: 100%;
}
.navbar-nav .nav-link {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
.favicn {
  width: 100px !important;
  height: 100px;
}
.imgWprBnr {
  width: 100%;
  padding: 0% !important;
  margin: 0% !important;
}
.imgWprBnr img {
  width: 100%;
}
.container-fluid.banerContainer {
}

.mini-cart-forsatt-button.btn.btn-secondary {
  border-radius: 10px !important;
  min-width: 90%;
  color: white;
  background: #1a512c !important;
  font-weight: bolder;
}
.text-link {
  color: inherit;
  text-decoration: inherit;
}
.bnanerBtn {
  background-color: #fff;
  color: #1a512c;
  border: 1px solid #fff;
  padding-left: 20px !important;
  padding-right: 20px;
}
.bannerParaText {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
}

.banner-header {
  overflow: hidden;
  white-space: nowrap;
  font-size: 2em;
  font-weight: bold;
  position: relative;
  color: white;
}
.grayed-out-image {
  filter: grayscale(100%);
  opacity: 0.7;
}
.yellowed-out-image {
  filter: sepia(1) hue-rotate(166deg) saturate(0.2);
}
.image-opacity-bestll {
  opacity: 0.7;
}
span.svara-bestall-overimage {
  position: absolute;
  color: #1a512c;
  text-align: center;
  max-width: 80%;
  left: 10%;
  right: 10%;
  top: 25%;
  font-size: 13px;
  font-weight: 800;
  background: rgb(255 255 255 / 21%);
  z-index: 10;
}

.not-grayed-out-image {
  filter: grayscale(0%);
  opacity: 1;
}
.banner-photo {
  width: 30%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-animation: fadein 6s linear forwards infinite;
  -moz-animation: fadein 6s linear forwards infinite;
  -ms-animation: fadein 6s linear forwards infinite;
  -o-animation: fadein 6s linear forwards infinite;
  animation: fadein 6s linear forwards infinite;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  16.67% {
    opacity: 1;
  }
  83.33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.urgent-message-holder {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  background: #ffc107;
  color: #000;
  display: flex;
  font-weight: bolder;
  flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
}
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  16.67% {
    opacity: 1;
  }
  83.33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }
  16.67% {
    opacity: 1;
  }
  83.33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  16.67% {
    opacity: 1;
  }
  83.33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  16.67% {
    opacity: 1;
  }
  83.33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.background-main-page {
  background: #00b09b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    -45deg,
    #96c93d,
    #00b09b,
    #6bda82,
    #56c1a8
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    -45deg,
    #96c93d,
    #00b09b,
    #6bda82,
    #56c1a8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: 400% 400%;
  color: white;
  animation: gradient 15s ease infinite;
  padding: 8% !important;
  min-height: 70vh;
}

.background-main-page-jul {
  background-image: url("http://gronsakskompaniet.se/backgroundjyl.jpg");
  background-size: 100% ;
  color: white;
  /* animation: gradient 20s ease infinite; */
  padding: 8% !important;
  min-height: 70vh;
}

.banner-text-jul {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  /* i want here a contour that is outside of the font for background of fonts */
/*   -webkit-text-stroke: 1px #ffffff; */
  color: #ffffff;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
 /*  text-shadow: 0px 0px 10px #ffffff; */

}
.background-main-page-blog-section {
  background: #00b09b; /* fallback for old browsers */
  background-size: 400% 400%;
  color: white;
  padding: 8% !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.loginTitle {
  padding-left: 110px;
  font-size: 20px !important;
  font-weight: bold !important;
}
.signupText {
  color: #222 !important;
  text-align: center !important;
  margin: 0 auto;
}
.signupText-homepage {
  color: #222 !important;
  text-align: center !important;
  margin: 0 auto;
  color: white !important;
}
.forgetText {
  color: #222 !important;
}
.close {
  font-size: 30px !important;
}
.MyaccntBtn:focus {
  outline: none !important;
}
.ListText.list-group-item {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 10px; /*padding-left:50px; padding-right:50px;*/
}
.prdctOvrvwLstext.ListText.list-group-item {
  padding-left: 10px;
  padding-right: 10px;
}
.InputincrementClass:focus,
.InputincrementClass:active {
  outline: none !important;
}
/* .ListWp.cartIcnList .MuiList-root, .ListWp.cartIcnList .MuiList-padding,.ListWp.cartIcnList .jss1 { 
 background-color:#e1f0c5!important;
} */
.form-control.InputincrementClass:focus {
  outline: none !important;
}
.btn.kiloBtnClass {
  font-size: 12px;
  font-weight: 600;
  background-color: #1ea140;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  border: 1px solid #1ea140;
}
.kilocheck.MuiSvgIcon-root {
  font-size: 20px !important;
}
.kiloWp {
  padding-top: 10px;
}
.form-control:focus {
  box-shadow: none !important;
}
.circulrIcn {
  position: absolute;
  right: 10px;
  top: 80px;
}
.accountCirculrIcn {
  color: #1ea140 !important;
}
.AccountCirculrIcnprdList {
  position: absolute;
  right: 10px;
  top: 40px;
  color: #1ea140 !important;
}
.KampanjSpan {
  background: red !important;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 10px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.ProductDetailColWp {
  margin-top: 30px;
}
.ProductDetailColWp .list-group-horizontal {
  width: 100%;
}
.ProductDetailColWp .CounterListWp .list-group-item {
  padding: 5px 20px !important;
  font-size: 20px;
}
.LadaList .list-group-item {
  padding: 10px;
}
.list-group-item.LadaListCol2 {
  padding-left: 25px;
  padding-right: 25px;
}
.list-group-item {
  font-size: 14px;
  font-weight: 600;
  padding: 0.7rem 1.25rem;
}

.IncrementalInputWp .list-group-item {
  font-size: 20px;
  /* padding-top: 0!important; */
}
.DeleteSpanWp {
  position: absolute;
  right: 10px;
  top: 0;
}
.SelectGrid .MuiOutlinedInput-input {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.orderconfList .product-line-grid-left.col-md-3 {
  text-align: center;
}
/* .orderconfList .product-line-grid-left img {
  width: 30% !important;
} */
.prodctListOverviewp .prdOverLadaList {
  width: 100%;
}
.orderconfList .ProductQty {
  margin-top: 30px;
}
.orderconfList .product-price {
  padding-top: 25px;
}
.linkTextClass {
  color: #1ea73f !important;
  font-weight: 600;
  cursor: pointer !important;
  text-decoration: underline;
}
.ListDeleteIcn {
  color: #b3b3b3;
  margin-left: 155px !important;
}
.ListDeleteIcnItem {
  color: #b3b3b3;
  margin-left: 8px;
}
.ListDeleteIcnItemStatus {
  color: #b3b3b3;
  margin-left: 8px;
  filter: grayscale(0%);
  opacity: 1;
}
.deleteOutlineIcn {
  color: #b3b3b3;
}
.dataListCheck {
  margin-left: 1.4rem;
  margin-bottom: 10px;
}
.MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  word-break: break-word;
  word-wrap: break-word;
}
.form-control.incrementText {
  font-size: 12px;
  text-align: center;
  border-left: none;
  width: 65px;
  padding-top: 21px;
  padding-bottom: 23px;
  border-radius: 0;
  border-right: none;
  padding-left: 15px;
}
.form-control.incrementText-new-checkout {
  font-size: 12px;
  text-align: center;
  border-left: none;
  width: 65px;
  padding-top: 21px;
  padding-bottom: 23px;
  border-radius: 0;
  border-right: none;
  padding-left: 15px;
}
.form-control.incrementText-lists {
  font-size: 12px;
  text-align: center;
  border-left: none;
  pointer-events: none;
  width: 55px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 15px;
  background: #1a512c;
  color: white;
}
.CartItems-new {
  list-style-type: none;
}
.form-control.incrementText-lists:hover {
  background: #1a512c;
  color: white;
}
.form-control.incrementText-lists:focus {
  background: #1a512c;
  color: white;
}
.form-control.incrementText-minicart {
  font-size: 10px !important;
  text-align: center;
  border-color: #1a512c !important;
  width: 60px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 15 !important;
  padding-left: 10px;
}
.MuiSvgIcon-root.shoppincrtIcn {
  color: #fff !important;
  font-weight: bolder !important;
}
.TopNav .MuiSvgIcon-root.shoppincrtIcn {
  color: #fff !important;
  font-size: 1.5rem;
  font-weight: bolder !important;
}
.TopNav .MyaccntBtn.linkclass {
  background: black !important;
  border: transparent !important;
  color: #fff !important;
}
.SaveMultiListWp .MuiTypography-root {
  font-weight: 600;
}
.SaveMultiListWp .ListItemName {
  font-weight: 400;
}
.topSellprodListwp .list-group.list-group-horizontal {
  width: 100%;
}
.filterList .MuiList-root {
  display: inherit !important;
}
.MuiList-root.MuiMenu-list {
  display: inherit !important;
  padding-left: 10px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}
.SaveMultiListWp .list-group {
  font-size: 14px !important;
}
.styckColWp .form-control {
  font-size: 12px !important;
}
.filterList .MuiMenuItem-root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}
/*Browse file css*/
.browseFileWp .thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: 4px solid #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px hsla(0, 0%, 4%, 0.2);
  line-height: 0;
  width: 200px;
}
.browseFileWp .small-9 {
  width: 75%;
  position: relative;
  margin-left: 15px;
}
.browseFileWp .small-centered,
.browseFileWp .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}
.browseFileWp label.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1ea140;
  color: #fff;
}
.browseFileWp .inputFile {
  display: none;
}
.NofilechoosenText {
  padding-left: 10px;
  font-size: 12px;
}
.termcondnWp {
  color: #1ea140;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.LadaListWp.list-group.list-group-horizontal {
  width: 100%;
}
/*Browse file css end*/
/*schedule Delivery Text*/
.selectTimeDeliveryText {
  font-size: 14px;
}
.konsumentText {
  font-size: 16px;
  font-weight: 600;
  color: #1ea140;
  text-align: center;
}
.placeOrderText {
  text-align: center;
  font-size: 14px;
}
/*New css end*/

/*Submenu css start*/
.SubmenuWp .dropdown-menu.show {
  position: absolute;
  left: 155px;
  top: 0;
}
.desktopdisplyNone {
  display: none;
}
/* .container-fluid.Wraper.TopSellimgWp {
  padding-left: 50px;
} */
.col-md-12.absoluteBtnCol {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 10px !important ;
}
.prodOverviewCard {
  height: 100%;
}
.prodOverviewCard.card {
  border: none !important;
}
.topsellingcardBody {
  margin-bottom: 35px;
}
.ProductOverviewCardBody {
  margin-bottom: 45px;
}
.MuiButton-label:focus {
  outline: none;
}
span.MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
  font-weight: bolder;
}
.loadingbtn {
  border: 2px solid #21a63f !important;
  border-radius: 0 !important;
  background: #fff !important;
  color: #212529 !important;
}
.loadingbtn:hover {
  background: #21a63f !important;
  color: #fff !important;
}
.productOverviewCol {
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.productOverviewCol:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.col-md-9.overviewRightCol {
  padding-left: 0;
}
/*active btn start*/
.BtnSelection-btn-group {
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  padding-left: 10px;
  padding-right: 10px;
}
.BtnSelection-btn-group .active {
  background-color: #1ea140;
  border: none;
  color: #fff;
  padding: 8px 10px;
}
.BtnSelection-btn-group .active .MuiSvgIcon-root.btnselectionCheck {
  color: #fff;
  display: block !important;
}
.BtnSelection-btn-group button {
  background-color: #cccccc;
  color: #222;
  border: none;
  padding: 8px 20px;
  font-size: 12px;
  display: inline-flex;
}
.BtnSelection-btn-group button:focus {
  outline: none !important;
}
.BtnSelection-btn-group button:last-child {
  margin-left: 10px;
}
.MuiSvgIcon-root.btnselectionCheck {
  font-size: 16px;
  margin-right: 3px;
  color: #cccccc;
  display: none !important;
}
/* .css-26l3qy-menu:active{ background-color: red;}
.css-4ljt47-MenuList:hover,.css-4ljt47-MenuList:active{ background-color: red;} */
input::-webkit-outer-spin-button,
.LadaSelectWp {
  margin-top: 10px;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disabled-link {
  pointer-events: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*react select css start*/

/* .css-yk16xz-control:hover{ border:none!important;} */
.selectDropDowWp .css-1pahdxg-control {
  border: hsl(0, 0%, 80%) !important;
}
/* .css-1pahdxg-control:hover, */
.selectDropDowWp .css-2b097c-container:active,
.css-2b097c-container:focus {
  border: none !important;
}
.selectDropDowWp .css-1pahdxg-control {
  box-shadow: none !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
}
/* .ProductDetailColWp .InputincrementClass.form-control{ font-size: 1.2rem!important;} */
/*Media query start*/
/*Admin Dashboard Css*/
.admin-header {
  margin-left: 240px;
}
.MuiDrawer-root.MuiDrawer-modal {
  display: none;
}
.sideDrawerWp .MuiTypography-displayBlock {
  font-size: 16px !important;
  text-decoration: none !important;
}
.MuiList-root a {
  text-decoration: none !important;
}
.sideDrawerWp .MuiList-root {
  display: inherit;
}
.sideDrawerWp .MuiListItem-gutters {
  padding: 10px 16px !important;
}
.stretch-card .MuiSvgIcon-root.colorIcn {
  color: #fff !important;
  font-size: 20px !important;
}
.CardHedr.card-title {
  font-size: 16px;
  line-height: 17px;
  color: #231f20;
  font-family: Akkurat, sans-serif !important;
  font-weight: 600 !important;
}
.cardSubText {
  color: #777;
  font-size: 14px;
  font-weight: normal;
}
.totalValueText {
  color: #1ea140 !important;
  font-size: 20px;
  font-weight: 600;
}
.TotlText {
  color: #777;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
}
.cardIcnWpr {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px;
  background-color: #1ea140;
}
.stretch-card .card {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  height: 315px;
}
.MuiTab-root,
.MuiFormLabel-root,
.MuiInputBase-root {
  font-family: Akkurat, sans-serif !important;
}
.sideDrawerWp .MuiListItemText-root {
  color: #777;
  text-decoration: none !important;
}
.Top5Text {
  font-size: 13px;
  font-weight: normal;
  color: #777;
  padding-bottom: 5px;
}
.canvsTitle {
  padding: 20px;
  font-weight: 600;
}
.mainCardRow {
  margin-top: 20px;
}
.TopNavWpr .MuiAppBar-colorPrimary,
.AdminDashWp .MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
*,
*::before,
*::after {
  box-sizing: border-box !important;
}
/* .TopNavWpr  .dbCardCol .MuiAppBar-colorPrimary{ background-color:#1ea140;} */
.MuiAppBar-colorPrimary.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.AdminDashWp .dbCardCol .MuiTab-root {
  min-width: auto !important;
}
.MuiTab-wrapper {
  font-size: 13px;
  color: #222;
  font-weight: bold !important;
}
.AdminDashWp .dbCardCol .MuiTab-root,
.AdminDashWp .dbCardCol .MuiTabs-root {
  min-height: 30px !important ;
}
.AdminDashWp .dbCardCol .MuiBox-root {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 10px;
}
.AdminDashWp .dbCardCol .MuiBox-root .MuiInputBase-input,
.AdminDashWp .card .MuiBox-root .MuiInputBase-input {
  padding: 20px 6px 20px !important;
  font-size: 12px !important;
}
.card-text.totlOrders {
  font-weight: 600;
  color: #777;
  text-align: center;
}
.mainHeadrText {
  font-weight: 600;
  color: #777;
  text-align: center;
  margin-top: 20%;
  font-size: 14px;
  margin-left: 15px;
}
.MenuWp .searchWpr.menuSearch {
  margin-bottom: 0 !important;
}
.sidebarHeader {
  color: #222 !important;
}
.topsellingcardBody.card-body {
  padding-left: 0;
  padding-right: 0;
}
.selectDropDowWp .css-1uccc91-singleValue {
  text-align: center;
  left: 0 !important;
  right: 0 !important;
}
.MuiTabs-flexContainer a {
  text-decoration: none !important  ;
}
/* .items-menu.collapse.show.navbar-collapse:after{     */

/* .sidebar  .dropdown-toggle:after{  
 font-family: "Font Awesome 5 Free";
   font-weight: 900;
   content: "\f105"!important;
   font-style: normal;
   display: inline-block;
   font-style: normal;
   font-variant: normal;
   text-rendering: auto;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   background: 0 0;
   position: absolute;
   right:30px!important;
   top: 15px;
   border: none !important;
} */
/* .sidebar  .menu-open.nav-item .topDropdown.dropdown-toggle:after{ transform: rotate(90deg);right:17px!important;} */
/* .sidebar .dropdown-toggle.nav-link .items-menu.submenucollpse:after{transform: rotate(180deg);  } */
.dropMenunav.dropdown-toggle :after {
  transform: rotate(-90deg) !important;
  right: 17px !important;
}
/* .dropMenunav.dropdown-toggle .items-menu.submenucollpse.navbar-collapse.submenudown:after{ transform: rotate(90deg)!important;right:17px!important;}*/
.dropMenunav.dropdown-toggle.NewDrop
  > .items-menu.submenucollpse.navbar-collapse.submenudown:after {
  transform: rotate(90deg) !important;
  right: 17px !important;
}
.nav-item .NewDrop::after {
  transform: rotateZ(0deg) !important;
  display: inline-table;
}
.Submenuopen .NewDrop::after {
  transform: rotateZ(90deg) !important;
  display: inline-table;
}

.MobView .items-menu {
  margin-left: 10px;
}
.MobView .menu-open.nav-item .dropdown-toggle::after {
  transform: rotateZ(0deg) !important;
  display: inline-table;
}
.MobView .nav-item .dropdown-toggle::after {
  transform: rotateZ(-90deg) !important;
  display: inline-table;
}
.MobView .nav-item .NewDrop::after {
  transform: rotateZ(-90deg) !important;
  display: inline-table;
}
.MobView .Submenuopen .NewDrop::after {
  transform: rotateZ(0deg) !important;
  display: inline-table;
}

/* .WithStyles\(ForwardRef\(InputBase\)\)-input-16{ background-color: #1ea140!important; color:#fff!important; border:none!important;} */
.categoryNavWp div {
  width: 100% !important;
  margin-bottom: 10px !important;
  /* background-color: #1ea140!important; border:none!important; */
}
.categoryNavWp .MuiSelect-filled.MuiSelect-filled {
  background-color: #1ea140 !important;
}
.categoryNavWp .MuiSelect-select {
  height: 55px;
}
.categoryNavWp .MuiSelect-outlined.MuiSelect-outlined {
  background-color: #1ea140 !important;
  color: #fff !important;
  border: 1px solid #1ea140 !important;
}
.categoryNavWp .MuiSelect-outlined.MuiSelect-outlined:hover {
  border: none !important;
}
.btn.btnAddShop.btnUsername {
  padding-top: 13px;
  padding-bottom: 14px;
  font-size: 12px !important;
  border-radius: 8px !important;
}
/* .MuiInputLabel-formControl{ display: none!important;}
*/
.categoryNavWp .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled {
  color: #fff !important;
  font-size: 12px !important;
}
.categoryNavWp .MuiSelect-select:not([multiple]) option,
.categoryNavWp .MuiSelect-select:not([multiple]) optgroup {
  background: #f4f4f4 !important;
  color: #222 !important;
}
.categoryNavWp .MuiInputBase-root {
  color: #fff !important;
}
.categoryNavWp .MuiInputLabel-outlined {
  color: #fff !important;
}
.categoryNavWp .MuiSelect-icon {
  color: #fff !important;
}
.categoryNavWp .MuiInputLabel-formControl {
  font-size: 12px !important;
}

.SaveMuliListWraper .react-datepicker__day-name,
.SaveMuliListWraper .react-datepicker__day,
.SaveMuliListWraper .react-datepicker__time-name {
  width: 2.7rem !important;
}
.SaveMuliListWraper .react-datepicker-popper {
  width: 70% !important;
}
.categoryNavWp .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.categoryNavWp .form-control {
  border: none !important;
  background-color: #1ea140 !important;
  color: #fff !important;
  font-size: 12px;
  font-weight: 500;
}
.categoryNavWp {
  background-color: #138b59 !important;
  margin-right: 10px;
  text-align: center;
  border-radius: 5px;
}
.AdminSide.categoryNavWp {
  margin-right: 0;
  padding: 0;
  width: 250px;
}
.categoryNavWp .form-group {
  /* margin-bottom:5px; */
  margin-bottom: 0;
}
.categoryNavWp .form-control option {
  padding: 10px !important;
  color: #000;
  background: #fff;
}
/* .TopNavWpr .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters{  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%),
  0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)!important;
} */
.TopNavWpr {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%) !important;
}
.SaveMultiListWp .list-group-item + .list-group-item {
  border-top-width: 1px;
}
.list-group-horizontal > .list-group-item:first-child {
  border-radius: 5px !important;
  background-color: #25643a !important;
  color: white;
}

.list-group-horizontal > .newlistbuttons.list-group-item:first-child {
  background-color: #25643a !important;
  color: rgb(255, 255, 255);
  margin-right: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 12px;
  padding-left: 12px;
}
.list-group-horizontal > .newlistbuttons.list-group-item:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #25643a !important;
  color: rgb(255, 255, 255);
}
.list-group-horizontal > .list-group-item:last-child {
  border-radius: 5px !important;
  background-color: #1a512c;
  color: white;
}
.list-group-horizontal > .list-group-item {
  border-radius: 5px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 12px;
  padding-left: 12px;
}
/* .categoryNavWp .form-control option:hover{background-color: #e1f0c5!important;} */
.home-product-container {
  padding-left: 15px;
  padding-right: 15px;
}
/* .no-padding{ padding: 0!important;} */
.home-product-container > .row:first-child {
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}
.topSellprodListwp {
  background: #fff;
}
.ProductDetailWp .MuiAccordionSummary-content p {
  font-size: 14px;
  margin-bottom: 0;
}
.ProductDetailWp table {
  font-family: Akkurat, sans-serif !important;
  font-size: 14px !important;
}
.BannerTexthead a {
  font-family: "Zooja";
  font-size: 38px;
  text-decoration: none;
}
.BannerTexthead a:hover {
  text-decoration: none !important;
}
.VerticalDevider {
  width: 1px;
  background: #ced4da;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 50px;
}
.custom-select {
  padding-right: 10px;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  width: 100%;
  background-color: transparent;
}
select[disabled].minimal {
  background-image: none;
  opacity: 1;
  color: #000;
  padding: 0.3em 1em 0.5em 1em;
}
select[disabled].minimal option {
  color: #000;
}
select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
.LadaSelectWp select {
  /* styling */
  background-color: white;
  border: thin solid #e0e0e0;
  border-radius: 0;
  display: inline-block;
  font: inherit;
  line-height: 1.9em;
  padding: 0.3em 3.5em 0.5em 1em;
  text-align: center;
  text-align-last: center;
  font-size: 12px;
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: normal !important;
}
.LadaSelectWp select option:hover {
  background-color: #1ea140 !important;
}

/*ADmin dashboard  new css changes*/
table.topfiveTable th,
table.topfiveTable td {
  font-family: Akkurat, sans-serif !important;
  text-align: left;
  padding: 3px;
  border: none;
  font-size: 12px;
  font-weight: 400;
  color: #777;
}
table.todaysDeliveryTbl,
table.todaysDeliveryTbl th,
table.todaysDeliveryTbl td {
  text-align: left;
  font-family: Akkurat, sans-serif !important;
  padding: 5px;
  margin-bottom: 0 !important;
  border: none;
  border-bottom: none !important;
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-align: center;
}
table.todaysDeliveryTbl th {
  font-weight: 600;
  font-size: 14px;
}
.totlOrders {
  margin-top: 20%;
}
.ordertext {
  font-size: 18px;
  color: #777;
  font-weight: 600;
}
.CalenderIcon {
  position: absolute;
  right: 45px;
  color: #777;
  bottom: 85px;
}
.el {
  white-space: nowrap;
  max-width: 100px;
  padding: 0.5em 1em;
  background: white;
  overflow: hidden;
  text-overflow: ellipsis;
}
h3.CartHdr {
  font-size: 14px;
  font-weight: 600;
}
h3.CartHdr.tag {
  padding-right: 3rem;
}
h3.CartHdr.qtag {
  padding-right: 90px;
}

.bargraphCol {
  margin-top: 200px;
}
.MuiTypography-body1 {
  font-family: Akkurat, sans-serif !important;
}
.MuiTypography-h6 {
  font-family: Akkurat, sans-serif !important;
}
.btn.btn-secondary {
  letter-spacing: 0.8px;
  border-radius: 0 !important;
}
.form-control {
  font-size: 13px !important;
  font-weight: normal !important;
}
/* .SaveMultiListWp.list-group .list-group-item{border-bottom: none; }
.SaveMultiListWp.list-group .list-group-item:last-child{ border-bottom: 1px solid rgba(0, 0, 0, 0.125)!important;} */
.formWraper .MuiOutlinedInput-input {
  padding: 25px 10px !important;
}
.poperContainer .MuiButton-containedPrimary {
  margin: 0 !important;
}
.poperContainer .MuiButton-containedPrimary,
.logoutBtn.MuiButton-containedPrimary {
  background: #1ea140 !important;
}
.logoutBtn.MuiButton-root {
  min-width: auto !important;
}
.UserCreationWp .css-g1d714-ValueContainer {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.rmsc .dropdown-container {
  padding: 3px !important;
}
.css-1uccc91-singleValue {
  color: rgba(0, 0, 0, 0.54) !important;
}
.dropdown-heading-value {
  color: rgba(0, 0, 0, 0.54) !important;
}
.UserCreationWp .MuiPaper-root.MuiPopover-paper {
  top: 200px !important;
}
.UserCreationWp button[type="submit"] {
  margin: 1% 20%;
}
.UserCreationWp .userCreationCard {
  margin: 30px 20%;
}
.UserCreationWp .userCreationCard .dropdown-heading .dropdown-heading-value {
  width: 200px;
}
.GridWpr {
  width: 100%;
  display: flex;
}
.TypoWraper.MuiTypography-noWrap {
  width: 100% !important;
}
.setasDefaultBtn .MuiButton-label {
  font-size: 13px !important;
}
.mainClass {
  width: 100%;
  padding: 24px;
  flex-grow: 1;
}
.mobLogo {
  display: none !important;
}
.InfoTxt {
  font-family: Akkurat, sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
}
.InfoTxt p {
  text-align: justify;
}
.MenuDrawer .MuiIconButton-colorInherit {
  color: #ffffff !important;
}
.MenuDrawer {
  display: none !important;
}
.MenuDrawer .DeskDrawer {
  display: none;
}
.MenuDrawer .DrawerToolBar {
  min-height: auto;
}
.MenuDrawer .MuiToolbar-regular {
  background: transparent !important;
  display: initial;
}
.MenuDrawer header {
  background-color: transparent !important;
}
.headerClass .MenuDrawer header {
  background-color: #1a512c !important;
  min-height: 56px;
}

@media screen and (max-width: 478px) {
  .BnerTextCol.row {
    padding: 20%;
  }
}

.MenuDrawer .MuiSvgIcon-root {
  font-size: 1.8rem;
}
.DrawerCloseBtn {
  justify-content: flex-end !important;
}
.MobNavigation .MuiListItem-gutters {
  padding: 10px 16px !important;
  border-bottom: 1px solid #efefef;
}
.MobNavigation .MuiList-root {
  display: inherit;
}
.MobNavigation.MuiList-padding {
  display: block;
  padding: 5px;
}
.MobNavigation.MuiList-padding .MuiTypography-displayBlock {
  font-size: 14px !important;
  margin-bottom: 5px !important;
  padding-bottom: 5px;
}
.TopNav .navbar-toggler {
  display: none;
}
.MobNavigation li a.dropdown-toggle::after {
  position: absolute;
  left: 85%;
  top: 15px;
  right: 0;
}
.CartMobView {
  display: none !important;
}
.headerLink {
  font-size: 16px;
  margin-top: 5px;
  color: #000;
  text-decoration: underline;
}
.headerLink:hover {
  color: #1ea140;
}
.tableWp .MuiTablePagination-input,
.userlisttblwrap .MuiTablePagination-input {
  width: 100px;
}
.tableWp .MuiTablePagination-input .MuiTablePagination-select,
.userlisttblwrap .MuiTablePagination-input .MuiTablePagination-select {
  text-align: center;
  text-align-last: center;
}
/* product overview pg css start*/
.wrapper {
  max-width: 1380px;
  margin: 0 auto !important;
}
/* product overview pg css end*/
.list .dropdown-menu {
  max-height: 250px;
  overflow: overlay;
  margin: 0px;
}

.searchbar input {
  font-family: inherit;
  font-size: 14px;
  /* border-radius: 0; */
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  height: 46px;
}

.clearable-input {
  position: relative;
  display: inline-block;
}
.clearable-input > input {
  padding-right: 1.4em;
}
.clearable-input > [data-clear-input] {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: xx-large;
  /* font-weight: bold; */
  /* font-size: 1.4em; */
  padding: 0 0.2em;
  /* line-height: 1em; */
  cursor: pointer;
}
.clearable-input > input::-ms-clear {
  display: none;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchInput {
  width: 400px;
  border: 1px solid #1ea140;
  border-left: none;
  padding: 5px;
  height: 20px;
  border-radius: 0;
  outline: none;
}

.searchTerm:focus {
  color: #1ea140;
}

.searchButton {
  width: 50px;
  height: 46px;
  border: 1px solid #1ea140;
  /* border: 1px solid rgb(223, 225, 229); */
  /* background: #1ea140; */
  border-right: none;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  color: grey;
  border-radius: 0;
  cursor: pointer;
}
.redClass {
  color: red;
  font-size: 13px;
}
/* cart page css start 11-8-21 */

.delitxt {
  color: #1ea140 !important;
  font-size: 16px !important;
}

.hero-kontakt-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/src/assets/images/kontakta-oss.png");
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-kontakt-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.ContactUsWp .conatctUSCard.contactpg .MuiOutlinedInput-input {
  padding: 25px 10px !important;
}
.ContactUsWp .conatctUSCard.contactpg .MuiSelect-outlined.MuiSelect-outlined {
  padding: 5px 10px 25px !important;
}

.isPickUpCheck.MuiCheckbox-colorSecondary,
.isPickUpCheck.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ffc107 !important;
}
.customerlistwrap .MuiTableCell-root,
.userlisttblwrap .MuiTableCell-root {
  padding: 5px;
  font-size: 12px !important;
}
#simple-popover .MuiPaper-root.MuiPopover-paper {
  min-height: 230px;
}
.swal-icon--success__line--tip {
  left: 5px;
}
.swal-icon--info:after {
  top: 16px;
}
.swal-icon--warning__dot {
  margin-left: -3.5px;
}
.swal-icon--error__line--left {
  left: 12px;
}
.swal-icon--error__line--right {
  right: 14px;
}
.MuiTablePagination-input .MuiSelect-select.MuiSelect-select {
  padding-right: 24px !important;
}

@media print {
  .hide-on-print {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .TopSellimgWp .titleTxt {
    height: 42px;
  }
  /* .productOverviewCol{ padding-left: 0!important;} */
  .page-homepage .component-product-list {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 1366px) {
  .ProductOverview .bkggrey {
    height: 150px;
  }
  .TopNavWpr .GridWpr {
    justify-content: end;
  }
}
@media only screen and (max-width: 1024px) {
  .ProductOverview .bkggrey {
    height: 125px;
  }
  .ModalWp .modal-content {
    width: auto !important;
  }
  .container-fluid {
  }
  .sidebar {
    top: 0;
  }
  .UserCreationWp .userCreationCard {
    margin: 30px 0;
  }
}
@media only screen and (max-width: 991px) {
  #responsive-navbar-nav {
    margin-top: 0;
  }

  .svara-bestall {
    background: #b0b0b0 !important;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
    border-radius: 10px;
    padding: 3px 8px;
    text-align: center;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 31%;
  }
  .svara-bestall:hover {
    transition: transform 0.3s;
    transform: scale(1.3);
    font-size: 8px;
  }
  .svara-bestall .svara-bestall-hovertooltip {
    visibility: hidden;
    width: 120px;
    background-color: #1ea140;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
  }
  .svara-bestall:hover .svara-bestall-hovertooltip {
    visibility: visible;
    transition: transform 0.3s;
    opacity: 1;
    font-size: 8px;
  }
  svg.MuiSvgIcon-root.delete-icon-smaller {
    font-size: 17px;
    margin-right: 3px;
  }
  .MuiDrawer-root.MuiDrawer-modal {
    display: block;
  }
  .MuiTableFooter-root .MuiIconButton-root {
    padding: 6px;
  }
  .sliderwrapspace {
    width: 0 !important;
  }
  .searchbardiv .searchButton,
  .searchbardiv .searchbar input {
    height: 39px;
  }
  .MenuWp .bg-dark {
    margin-top: 35px !important;
    padding: 10px 35px;
  }
  /*   div#spara-alla-button {
    max-width: 200px;
    font-size: 10px;
    text-align: center;
  } */
  .mobLogo {
    display: inline-block !important;
  }
  .dektoplogo {
    display: none !important;
  }
  .col-md-3.productOverviewCol {
    padding-left: 0;
    padding-right: 0;
  }
  .BannerHeadText,
  .BannerWithoutImgHeadText {
    font-size: 28px;
  }
  .detailText,
  .BannerWithoutImgdetailText {
    font-size: 18px;
    top: 55px;
    text-align: center;
  }
  #should-be-hidden {
    display: none;
  }
  #tom-varukorg-id {
    display: block;
  }
  .searchbardiv .clearable-input {
    width: 100%;
  }
  .MenuWp .navbar-nav {
    flex-direction: row;
  }
  .MenuWp .dropdown-menu {
    position: absolute !important;
  }
  .MenuDrawer {
    display: flex !important;
  }
  .ProdsidebarWp {
    display: none;
  }
  .CartMobView {
    display: flex !important;
    margin: 10px 35px 0;
  }
  .Proditemcount,
  .CountWithoutImg {
    font-size: 18px;
  }
  .searchInput {
    width: calc(100% - 50px);
  }
  .SaveMultiListWp .list-group-item,
  .SaveMultiListWp .list-group .form-control {
    font-size: 12px;
  }
  .SaveMultiListWp .list-group.list-group-horizontal .list-group-item {
    font-size: 14px;
  }
  .SaveMultiListWp .MuiFormControlLabel-label {
    font-size: 12px !important;
  }
  .deliverydtmodal.modal .modal-header,
  .deliverydtmodal.modal .modal-body,
  .deliverydtmodal.modal .modal-footer {
    padding: 0.1rem 0.5rem !important;
  }
  /*   .deliverydtmodal.modal .modal-header,
  .deliverydtmodal.modal .modal-body,
  .deliverydtmodal.modal .modal-footer,
  .deliverydtmodal.modal .modal-title,
  .deliverydtmodal.modal .selectTimeDeliveryText,
  .deliverydtmodal.modal .DayPicker-Caption > div,
  .deliverydtmodal.modal .DayPicker-Weekdays,
  .deliverydtmodal.modal .DayPicker {
    font-size: 12px !important;
  } */
  .deliverydtmodal.modal .DayPicker-Weekdays,
  .deliverydtmodal.modal .DayPicker-Month {
    margin-top: 0.25em;
  }
  .DayPicker-Month {
    min-width: 90% !important;
    min-height: 80% !important;
  }
  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 400px;
  }
  .deliverydtmodal.modal .modal-dialog {
    margin: 5px auto;
  }

  .deliverydtmodal.modal .DayPicker-wrapper {
    padding-bottom: 0;
  }
  .deliverydtmodal.modal hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .selectTimeDeliveryText,
  .deliverydtmodal.modal .form-group {
    margin-bottom: 0 !important;
  }
  .deliverydtmodal.modal .form-group {
    margin-top: -10px;
  }
  .deliverydtmodal.modal .modal-header .close {
    padding: 0.5rem 1rem;
  }
  .deliverydtmodal.modal .DayPicker-Weekday,
  .deliverydtmodal.modal .DayPicker-Day {
    padding: 0.25em;
    font-size: 20px !important;
  }
  .deliverydtmodal.modal .DayPicker {
    display: block !important;
  }
  .btn.btnAddShop.btnUsername {
    width: 50%;
    text-align: left;
    margin-right: 0 !important;
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .categoryNavWp {
    /* width: 100%;
    margin-right: 0; */
    margin-bottom: 0px !important;
    vertical-align: top;
    margin-right: 0;
  }
  .categoryNavWp .form-group {
    margin-bottom: 0;
  }
  .categoryNavWp select.form-control:not([size]):not([multiple]) {
    height: calc(1.7rem + 2px);
  }
  .searchbardiv {
    min-width: auto !important;
    width: 100%;
    padding-left: 10px;
  }
  .MenuWp.mb-4 {
    margin-bottom: 0.75rem !important;
  }
  .MenuWp .navbar-collapse {
    flex-basis: auto;
  }
  .cartLeft .card-block.card-title {
    padding-top: 10px !important;
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }
  .card-block {
    font-size: 21px;
  }
  /* .TopNavWpr .MuiAppBar-colorPrimary,
  .TopNavWpr .MuiToolbar-regular {
    background: #1ea140 !important;
    color: #fff;
  }
  .TopNavWpr .MuiTypography-noWrap {
    color: #fff !important;
  } */
  .TopNavWpr .MuiIconButton-colorInherit {
    color: #000000;
  }
  .btnAddShop {
    padding: 0.375rem 0.5rem !important;
    letter-spacing: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .TopNavWpr .headerLink {
    font-size: 12px;
  }
  .TopNavWpr .MuiButton-root {
    padding: 4px 5px;
    line-height: 1.5;
  }
  .TopNavWpr .logoutBtn.MuiButton-root .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
  .TopNavWpr .logoutBtn.MuiButton-root {
    margin-top: 5px;
  }
  .prm10 {
    padding-right: 10px;
  }
  .plm10 {
    padding-left: 10px;
  }
  .BannerTexthead a {
    font-size: 20px !important;
  }
  .bannerParaText {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .imgWprBnr img {
    width: 100%;
    height: auto;
  }
  .imgWprBnr {
    height: auto;
    text-align: center;
  }
  .searchbardiv {
    width: 100%;
    margin-bottom: 5px;
  }
  /* .BnerTextCol{top:0 !important;} */
  .content.is-open {
    margin-left: 100%;
  }
  .sidebar.is-open {
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }
  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
  }
  .bannerWp {
    height: auto;
  }
  .bannerWp h1 {
    font-size: 16px;
    text-align: center;
  }
  .BannerCol .col-md-6 {
    margin: 0 auto;
    text-align: center;
  }

  .BannerHeadText {
    margin: 0 auto;
    text-align: center;
  }

  .bannerWp p {
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
  }
  .bannerWp .btn.btn-outline-success {
    text-align: center;
    margin: 0 auto;
  }
  .ListingWp {
    margin: 0 auto;
  }
  .ListingWp .col-md-2 {
    text-align: center;
  }
  .ProdRowWp .ProdWPTop {
    margin-bottom: 20px;
  }
  .bkggrey {
    height: inherit;
  }
  .prodImgWp img {
    width: 100%;
    height: 100%;
  }
  .TopSellimgWp .titleTxt {
    text-align: center !important;
  }
  .TopSellimgWp .card-text {
    text-align: center;
  }
  .TopSellimgWp .greenText {
    text-align: center !important;
  }
  .TopSellimgWp .MuiBox-root {
    text-align: center;
  }
  /*Deal of THe Day css*/
  .container-fluid.DealOftheDayWp {
    padding: 20px 10px;
    margin: 0;
  }
  .shopnowColml-3 {
    margin-left: 0px;
  }
  .ShopRow {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ShopNowCol {
    margin-bottom: 20px;
  }
  .clientHeadText {
    text-align: center;
  }
  .clintTestiTitl.card-title {
    text-align: center;
  }
  .desigText.card-subtitle {
    text-align: center;
  }
  .dailydisImg {
    padding-top: 0 !important;
    padding-bottom: 30px !important;
    text-align: center;
    margin-top: 10px;
  }
  .container-fluid.DailyDiscountWp,
  .container-fluid.LatestBlogContainer,
  .container-fluid.ProductDetailWp {
    padding-left: 30px !important;
    padding-right: 30px;
  }
  .container-fluid.Wraper {
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;
    margin-top: 15px;
  }
  .container-fluid.LatestBlogContainer .col-12 {
    margin-bottom: 20px;
  }
  /* .container-fluid.Wraper{ padding-left: 20px!important;} */
  .varietyText {
    margin-top: 20px;
    text-align: center;
  }
  .joinText {
    text-align: center;
    margin-bottom: 20px;
  }
  .footerWp.col-12 {
    margin: 0 auto;
    text-align: center;
  }
  .fooColWp.col-12,
  .footerCol.InfoCol,
  .listWp {
    margin: 0 auto;
    text-align: center;
  }
  .footerWp h5 {
    margin-top: 20px;
    text-align: center;
  }
  .footerWp .MuiSvgIcon-root.forwrdArrow {
    display: none;
  }
  .footerLastRow {
    display: none;
  }
  .ShopNowCol.ml-3 {
    width: 100%;
    margin-left: 0 !important;
  }
  .DailyText {
    text-align: center;
  }
  .subtext {
    text-align: center;
  }
  .paraText {
    text-align: center;
  }
  .buyNowClass.btn.btn-light {
    text-align: center;
  }
  .DailyDiscountWp {
    text-align: center;
    margin: 0 auto;
  }
  .dailydisImg.pt-0 {
    padding-top: 0 !important;
  }
  .container-fluid.ClientTestimonialWp {
    padding-left: 10px;
    padding-right: 10px;
  }
  /* .prodImgWp{ height: 250px;} */
  .TopNav .bg-dark {
    padding-left: 10px !important;
  }
  .css-2b097c-container {
    margin-bottom: 5px;
  }
  /* .ProdsidebarWp{ display: none;} */
  .productDetailContentWp.col-12 {
    text-align: center;
    margin: 0 auto;
  }
  .btnAddShop {
    margin-bottom: 20px;
  }
  .price.text-center {
    text-align: center !important;
  }
  .deleteOutlineIcn {
    margin-top: 12px;
  }
  /* .topSellprodListwp
 10px;}  */
  /*LAtestblog*/
  .latestblogcol {
    padding-left: 15px;
  }
  .latestblogcol3 {
    padding-right: 15px;
  }
  /*Header css start*/
  .MenuWp .bg-dark {
    padding: 7px 10px 10px;
    height: auto;
    margin-top: 0 !important;
  }
  .MenuWp .navbar-dark .navbar-toggler {
    border: 1px solid #1ea140 !important;
    background: #1ea140 !important;
  }
  .MenuWp .navbar-dark .navbar-nav .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MenuWp .dropdown-menu.show {
    background-color: #e1f0c5 !important;
  }
  .MenuWp #collasible-nav-dropdown {
    margin-top: 0;
  }
  .dropdown-divider {
    border: 1px solid #222;
  }
  .TopNav {
    margin-left: 0px;
    margin-right: 0px;
    min-height: 56px;
  }
  .MenuWp .navbar-dark .navbar-nav .nav-link {
    padding-left: 10px;
  }
  .MenuWp {
    margin-left: 0px;
    margin-right: 0px;
  }
  .navbar-collapse.show {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .MyaccntBtn.linkclass {
    padding-top: 3px;
    margin-top: 0px;
  }
  .btn.MyaccntBtn :focus,
  .btn.MyaccntBtn :active {
    outline: none !important;
    box-shadow: none;
  }
  .dollarIcn {
    margin-right: 10px;
  }
  .langIcn {
    margin-right: 10px;
  }
  /* .heaerContainer{ padding-right:10px; padding-left:10px;} */
  /*ProductDetail page*/
  /* .container-fluid.ProductDetailWp{ } */
  .productDetailContentWp {
    margin-bottom: 20px;
  }
  .productDeatilWp {
    width: 40%;
    margin: 0 auto;
  }
  .ProductDetailColWp {
    margin-top: 0;
  }
  .cartprodImg {
    width: 100%;
  }
  .SaveMultiListWp .product-image.cartprodImg {
    width: 120px;
    margin: 10px auto;
  }
  .product-line-grid-left .cartprodImg img {
    padding-bottom: 0 !important;
  }
  .product-line-grid-left {
    padding-top: 0;
  }
  .product-line-grid-left img {
    /* max-width: 100%;
    width: auto;
    height: 100%; */
    margin-top: 10px;
    padding: 0 20px;
  }
  .cartLeft .card-block.card-title {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .cartLeft.col-md-9 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .MainHdrTxt {
    font-size: 20px;
  }
  .prodListImgWpr.prodImgWp {
    width: 40%;
    margin: 0 auto;
    height: auto;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .bkggrey {
    background-color: transparent;
  }
  .talkText {
    text-align: center;
  }
  .NumberText {
    text-align: center;
  }
  .CartItems .list-group-item {
    padding: 5px 15px !important;
  }
  .desktopNone {
    display: block;
  }
  .ModalWp .modal-content {
    margin: 0 auto;
  }
  /* .TopSellimgWp .newSpan{ right:150px;} */
  /*footer css*/
  .footerCol.InfoCol {
    padding-left: 0;
  }
  .footerTopRow {
    padding-left: 10px;
    padding-right: 10px;
  }
  .preceedBtn {
    text-align: center;
  }
  .checkoutcartsummry {
    margin-top: 20px !important;
  }
  .col-12.latestblogcol2,
  .col-12.latestblogcol3 {
    padding-left: 0 !important;
  }
  /*ProductOverview css start*/
  .detailText {
    font-size: 18px;
    top: 50px !important;
    text-align: center;
  }
  .ProductOverview.container-fluid,
  .InfoWrap.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .col-md-12.ProdovrlistWpr {
    padding-left: 15px;
    padding-right: 15px;
  }
  /* .prodctListOverviewp{ margin-left: 10px;} */
  .ProductOverview .titleTxt {
    text-align: center;
  }
  .WeightText.card-subtitle {
    float: right;
  }
  .ProductOverview .greenText {
    text-align: center;
  }
  .filterList {
    text-align: center;
  }
  .FilterText {
    text-align: center;
  }
  /* .ProductOverview .prodImgWp{  max-height:250px;height:250px;} */
  .AutoScheduleText {
    text-align: center;
    float: inherit;
  }
  .AutoScheduleText h3 {
    float: inherit;
    font-size: 18px;
  }
  .listWpr {
    margin-bottom: 10px;
    margin-right: 20% !important;
    margin-left: 20% !important;
  }
  @media only screen and (max-width: 767px) {
    .listWpr {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .svara-bestall-cart {
      background: #ff0000 !important;
      color: #fff;
      font-weight: 600;
      font-size: 10px;
      border-radius: 10px;
      padding: 3px 8px;
      right: 20px;
      text-align: center;
      position: absolute;
      justify-content: center;
      align-items: center;
    }
    .svara-bestall-cart:hover {
      transition: transform 0.3s;
      transform: scale(1.3);
      font-size: 8px;
    }
    .svara-bestall-cart .svara-bestall-cart-hovertooltip {
      visibility: hidden;
      width: 120px;
      background-color: #1ea140;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
    }
    .svara-bestall-cart:hover .svara-bestall-cart-hovertooltip {
      visibility: visible;
      transition: transform 0.3s;
      opacity: 1;
      font-size: 8px;
    }
  }

  @media only screen and (min-width: 1000px) {
    .listWpr {
      margin-right: 15% !important;
      margin-left: 15% !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .listWpr {
      margin-right: 30% !important;
      margin-left: 30% !important;
    }
  }

  .displayBtn {
    float: right;
  }
  .saveBtn.scheduleBtn.btn {
    float: left;
    margin-top: 20px;
  }
  .prodordInfo {
    margin-top: 10px;
  }
  .MenuWp .searchWpr {
    padding-left: 0;
    padding-right: 0;
  }
  .container-fluid.banerContainer {
    padding-left: 0;
    padding-right: 0;
  }
  .topselColWp {
    margin-left: 10px;
    margin-right: 10px;
  }
  .topSellprodListwp.col-12 {
    margin: 0 auto;
  }
  .topSellprodListwp {
    padding: 0;
  }
  .TopSellimgWp .newSpan {
    top: 0;
    font-size: 8px;
    left: 0;
  }
  .TopSellimgWp {
    margin-top: 20px;
  }
  .mainHedingCol {
    margin-bottom: 10px;
  }
  /*Registration page css start*/
  .noteText {
    font-weight: normal;
    color: #929292;
    text-transform: none;
  }
  .ProductOverview .titleTxt {
    height: auto;
    margin-top: 45px;
  }
  /*Registratio page css end*/
  .desktopdisplyNone {
    display: block;
  }
  .container-fluid.Wraper.TopSellimgWp {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .col-md-12.absoluteBtnCol {
    bottom: 0;
  }
  .topSellprodListwp {
    margin-bottom: -1px;
  }
  .mainHeadingText {
    margin-top: 0;
  }
  .col-md-3.ProdsidebarWp {
    display: none;
  }
  .topsellingcardBody.card-body {
    padding: 0;
    margin-bottom: 25%;
  }
  /*toggleBtn css*/
  .BtnSelection-btn-group {
    margin-bottom: 5px;
  }
  .card-text.LadaSelectWp {
    margin-top: 10px;
  }
  .IncrementalInputWp .list-group.list-group-horizontal {
    margin-bottom: 35px !important;
  }
  .form-control.incrementText {
    width: 80px;
    height: 30px;
    padding: 15px 0.75rem;
  }
  .SaveMultiListWp .form-control.incrementText {
    height: 46px;
  }
  .CartItems .my-3 {
    margin-bottom: 0.5rem !important;
  }
  /* .container-fluid.Wraper.SaveMuliListWraper{ padding-left:15!important; padding-right:0!important;}  */
  .productOrderITemListWp {
    padding-left: 15px;
    padding-right: 15px;
  }
  .stretch-card .card {
    margin-bottom: 10px;
  }
  .mainCardRow {
    margin-top: 0px;
  }
  .MenuWp .searchWpr.menuSearch {
    margin-bottom: 15px !important;
  }
  /* .categoryNavWp {
    width: 100%;
    margin-bottom: 10px !important;
  } */
  .form-control.inputselectWpr {
    font-size: 12px !important;
  }
  .homeTab {
    padding: left 10px !important;
  }
  .page-homepage .home-product-container {
    margin-bottom: 20px;
    padding-left: 0;
  }
  .totlOrders,
  .mainHeadrText {
    margin-top: 20px;
  }
  .product-line-grid-body.col-md-10 {
    margin-bottom: 20px;
  }
  .bargraphCol {
    margin-top: 0;
  }
  .verticalLine {
    display: none !important;
  }
  .btn.viewBtn {
    margin-top: 10px;
  }
  .stretch-card .card {
    height: auto;
  }
  .makeStyles-root-1 {
    display: inherit !important;
  }
  .totlOrders {
    margin-bottom: 20px;
  }
  .TotlText {
    bottom: 0px;
  }
  .col-md-9.overviewRightCol {
    padding-left: 15px;
  }
  .BannerHeadText,
  .InfoBannerHeadText {
    font-size: 35px;
  }
  .MuiToolbar-regular {
    width: 100% !important;
  }
  .product-line-info,
  .SaveasListWp {
    text-align: center;
  }
  .SaveMultiListWp .ListItemName {
    text-align: center;
    margin-top: 0 !important;
  }
  #responsive-navbar-nav {
    margin-top: 5px;
  }
  .CartMobView {
    margin: 0 10px;
    margin-top: -7px !important;
    padding-bottom: 10px;
  }
  .deliverydtmodal .notation > input[name="pickUp"],
  .deliverydtmodal .notation > input[name="delivery"] {
    height: 12px;
    width: 12px;
  }
  .product-line-info a.product_name,
  .CartItems,
  .form-control.incrementText {
    font-size: 12px !important;
  }
  .CartItems .Qty .justify-content-center {
    align-items: center;
  }
  .CartItems .cart-line-product-actions .deleteOutlineIcn,
  .CartItems .remove-from-cart.btn.btn-primary {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    vertical-align: top;
  }
  .CartItems .btn.saveBtn {
    font-size: 12px !important;
    padding: 3px 10px !important;
  }
}
@media only screen and (max-width: 479px) {
  .btn.btnAddShop.btnUsername {
    width: 100%;
  }
  .categoryNavWp {
    width: 100%;
  }
  .DayPicker {
    font-size: 14px !important;
  }
  .LoginContainer.LoginModal {
    padding: 0 !important;
  }
  .BnerTextCol {
    top: 10px !important;
  }
  .BannerTexthead a {
    font-size: 16px !important;
  }
  .bannerParaText {
    margin-top: 0px;
    font-size: 10px;
  }
  .bannerWp h1 {
    margin-bottom: 0;
  }
  .prodctListOverviewp .SelectClass {
    padding-inline: 16px 5px;
  }
  .prodctListOverviewp .SelectClass.dropdown .btn-primary {
    font-size: 12px !important;
    padding-right: 10px !important;
  }
  /* .productOverviewCol {
    flex-grow: 1 !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  } */
  .home-product-container {
    padding-left: 0;
    padding-right: 0;
  }
  .col-md-12.ProdovrlistWpr {
    padding-left: 0;
    padding-right: 0;
  }
  .BannerHeadText {
    font-size: 24px;
    top: 44px;
  }
  .InfoBannerHeadText {
    font-size: 24px;
    top: 40px;
  }
  .detailText {
    top: 65px;
    font-size: 12px;
  }
  .PriceTag {
    font-size: 18px;
  }
  .topsellingcardBody {
    margin-bottom: 5px;
  }
  .topsellingcardBody.card-body {
    margin-bottom: 50%;
  }
  .ProdListImgWp img {
    width: 75%;
  }
  .ProductOverview .bkggrey {
    height: auto;
  }
  /* .list-group-horizontal > .list-group-item:first-child,
 .list-group-horizontal > .list-group-item:last-child
 {
  padding: 10px 20px!important;
 } */
  /* .searchbardiv {
    min-width: 180px !important;
  } */
  .MenuWp #responsive-navbar-nav {
    display: block !important;
  }
  .ProductOverview .titleTxt {
    margin-top: 90px;
  }
  .searchbardiv {
    padding-left: 0;
  }
  /* .searchButton {
    height: 46px!important;
  } */
  /* .orderconfList .product-line-grid-left img{
    width: 100%!important;
  } */

  /* search inpou css start */

  /* search input css end */
  .savemultiwidth {
    width: 300px !important;
  }
  .tableWp .DashboardTbl .MTableToolbar-spacer-39 {
    display: none;
  }
}
@media (min-width: 600px) {
  .TopNavWpr .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    min-height: 60px !important;
    height: 60px !important;
  }
}
@media only screen and(min-width: 576px) {
  .BannerCol {
    max-width: 540px;
  }
  .LoginModalWp.modal-dialog {
    margin: 3rem auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .dbCardCol.col-lg-3 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 10px;
  }

  .svara-bestall {
    background: #b0b0b0 !important;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
    border-radius: 10px;
    padding: 3px 8px;
    text-align: center;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 31%;
  }
  .svara-bestall:hover {
    transition: transform 0.3s;
    transform: scale(1.3);
    font-size: 8px;
  }
  .svara-bestall .svara-bestall-hovertooltip {
    visibility: hidden;
    width: 120px;
    background-color: #1ea140;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
  }
  .svara-bestall:hover .svara-bestall-hovertooltip {
    visibility: visible;
    transition: transform 0.3s;
    opacity: 1;
    font-size: 8px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .container-fluid.DealOftheDayWp {
    padding: 50px 10px 40px 35px;
  }
  /* .container-fluid.Wraper,.container-fluid.LatestBlogContainer,.container-fluid.ProductDetailWp{ padding-left:10px!important; padding-right: 10px;} */
  .col-md-9.cartLeft {
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .col-md-4.cartright {
    width: 100%;
  }
  .container-fluid.DailyDiscountWp {
    padding-left: 0px;
    padding-right: 0px;
  }
  .container-fluid {
    overflow: hidden;
  }
  .col-md-3.ProdsidebarWp {
    padding-right: 15px;
  }
  .sidebar {
    width: 100%;
    min-width: 150px;
  }
  .joinText {
    font-size: 14px;
    text-align: center;
  }
  .joinText .MuiSvgIcon-root {
    font-size: 0.8rem !important;
    margin-right: 5px;
  }
  .MainHdrTxt {
    font-size: 20px;
  }
  .card.cart-summary {
    margin-top: 20px;
  }
  .MenuWp .searchWpr {
    width: 250px;
    padding-left: 10px;
    padding-right: 10px;
  }
  /* .col-md-3.container.TopSellimgWp { max-width: 100%!important;} */
  .TopSellimgWp .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .TopSellimgWp .kilocheck {
    display: none;
  }
  .container-fluid.banerContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
  .container-fluid.Wraper.TopSellimgWp {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  /* .col-md-3.productOverviewCol{padding-left: 5px; padding-right: 5px; } */
  .prodOverviewCard .card-body {
    padding: 0 !important;
  }
  .ProductOverview .titleTxt {
    font-size: 12px;
  }
  /* .col-md-12.absoluteBtnCol{ padding-left: 0; padding-right: 0;} */
  .col-md-12.absoluteBtnCol {
    bottom: 15px;
    padding: 0 10px;
  }
  .ProductOverview.container-fluid,
  .InfoWrap.container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-md-9.overviewRightCol {
    padding-left: 15px;
    padding-right: 15px;
  }
  .CartItems {
    padding-left: 15px;
  }
  .continueShopBtn {
    margin-left: 15px;
  }
  .cartLeft.col-md-9 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /*.sidebar.is-open {min-width:50%;max-width:50%;}
 .content.container-fluid{ width:Calc(100% - 240px);} */
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .Qty.col-sm-4 {
    margin-left: 30%;
  }
  .sidebar.content.container-fluid {
    width: Calc(100% - 240px);
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .CartItems .list-group-item {
    padding: 5px 8px !important;
  }
  .circulrIcn {
    top: 35px;
    right: 0;
  }
  .TopSellimgWp .titleTxt {
    font-size: 12px;
  }
  .CommercialText {
    font-size: 12px;
  }
  .BtnSelection-btn-group button {
    padding-right: 5px;
    padding-left: 5px;
  }
  .InputincrementClass.form-control {
    padding-left: 1px;
    padding-right: 1px;
  }
}

/* info icon css */
.InfoSpan {
  position: absolute;
  right: 10px;
  top: 33px;
}
.InfoSpan .MuiSvgIcon-root {
  color: #eb7373;
}
.validationGuide {
  display: none;
  right: 0;
  top: -70px;
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 10px;
  font-weight: 600;
}
.InfoSpan:hover + .validationGuide {
  display: block;
  width: auto;
  height: auto;
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.validationGuide:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 12px;
  margin-left: -50px;
  width: 0;
  height: 0;
  border-top: solid 10px #d8d8d8;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}
@media screen and (min-width: 768px) and (max-width: 993) {
}
/* @media only screen and (min-width: 992px){
 .BannerCol{ max-width: 960px;}
}
@media only screen and (min-width: 768px){
 .BannerCol{ max-width:720px;}
}
@media  only screen and(max-width: 757px){
 .verticalDivider{ display:none;}
}  */

/*Sidebar css*/
/* 
.SidebarWp .sidebar {
 min-width: 220px;
 max-width: 220px;
 background: #7386d5;
 color: #fff;
 margin-left: -250px;
 transition: all 0.5s;
}

.SidebarWp .sidebar.is-open {
 margin-left: 0;
 transition: 0.5s;
}

.SidebarWp .sidebar-header {
 background: #6d7fcc;
}

.SidebarWp .sidebar-header h3 {
 color: #fff;
 padding: 1em;
}

.SidebarWp .sidebar ul p {
 color: #fff;
 padding: 10px;
}

.SidebarWp .menu-open {
 background: #6d7fcc;
}

.SidebarWp .nav-item:hover {
 color: #7386d5;
 background: #fff;
}

.SidebarWp .items-menu {
 color: #fff;
 background: #6d7fcc;
}

.SidebarWp li a.dropdown-toggle::after {
 display: inline-flex;
 position: relative;
 left: 60%;
 top: 10%;
}

.SidebarWp .sidebar-header > span {
 position: relative;
 float: right;
 margin: 0.5em;
 font-size: 2rem;
 cursor: pointer;
 display: none;
}
.SidebarWp .side-menu {
 height: calc(100vh - 130px);
 overflow-y: scroll;
}

.SidebarWp .side-menu::-webkit-scrollbar {
 width: 10px;
}

.SidebarWp .side-menu::-webkit-scrollbar-thumb {
 background: #5466b3;
 border-radius: 3px;
}

.SidebarWp .side-menu::-webkit-scrollbar-thumb:hover {
 background: #3a4c99;
}


.SidebarWp .content {
 padding: 20px;
 margin-left: 0;
 height: 100vh;
} */

@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  /* .searchbar input {
    height: 48px;
  } */
}
/* cart page css end */

.savemultiwidth {
  width: 200px;
}

.listTop {
  width: max-content;
  overflow: scroll;
}

@media only screen and (min-width: 1920px) {
  /* .wrapper{
  max-width: 1920px;
  margin: 0 auto;
  } */
}
.savedListMod {
  width: 200px;
}
.modelwidth .modal-content {
  width: auto;
}
.toggalSave {
  color: #1ea140 !important;
}
.modal-backdrop.show {
  width: auto;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100%;
  background-color: #093925;
}
/* @media (min-width: 576px){
.modal-dialog {
    max-width: 404px!important;
    margin: 1.75rem auto;
}
} */
/* .modelwidth{
  width:375px!important;
} */
/* 
.savelistModel{
  width: 400px !important;
  margin-top: 200px;
 padding: 5px;
 vertical-align: middle;
}
.savedListInput{
  width: 210px !important;
 height:40px !important;

}
 .savebtn{
  width:13rem;
  color: #fff !important;
  margin-right: 23px !important; 
}
.savelistModel{
  margin-left: 40% !important ; 
  width:300px !important;
  }
.toggalSave{
  position:fixed;
  margin-left: -115px !important;  
  color: #1ea140 !important;
  margin-bottom: 2px;
}
#saveListFooter {
  padding-top: 0 !important;
  border-top: 0 none !important;
}
@media only screen and (max-width: 768px) and (max-width: 1920px) {
  .savelistModel{
   margin-left: 40%;
   margin-top: 40%;
   width: 240px !important;
  
  }
  .savedListInput{
    width: 11rem !important;
   height:40px !important;
  
  }
  .savedListMod{
    color: hsl(0,0%,20%);
   width: 10rem; 
} 
.toggalSave{
  position:fixed;
  margin-left: -90px !important;  
  color: #669f34 !important;
}
.savebtn{
  width:10rem;
  color: #fff !important;
  margin-right: 14px !important; 
}} */

/* css for loader start */
.inner-loader-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* position: absolute !important; */
  z-index: 99999;
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.2);
}
.inner-loader-wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
}
.inner-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #1ea140;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite !important; /* Safari */
  animation: spin 2s linear infinite !important;
  margin: 2rem auto 0;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.DayPicker-Day--pickUpDay {
  color: #ffc107;
  background-color: #fffdee;
}
.DayPicker-Day--pickUpDay.DayPicker-Day--selected:not(
    .DayPicker-Day--disabled
  ):not(.DayPicker-Day--outside) {
  color: white !important;
  background-color: #ffc107 !important;
}
div[isavai="true"]
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  background-color: rgb(255, 40, 40) !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  position: relative;
  background-color: #1ea140 !important;
  color: #ffffff !important;
}
/* .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
  background-color: #82bf4a; 
} */
.DayPicker-Day--today {
  color: black !important;
  font-weight: 700;
}
.DayPicker-Day--pickUpDay.DayPicker-Day--today {
  color: #ffc107 !important;
  background-color: #fffdee;
}
.DayPicker-Day--disabled.DayPicker-Day--today {
  color: #dce0e0 !important;
}
/* .notation > input:checked {
  background-color: #ffde76;
} */
.notation {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}
.notation > input[name="pickUp"] {
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  background-color: #ffde76;
}
.notation > input[name="delivery"] {
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  background-color: #000000;
}
/* .notation > input[name=""] {
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  background-color: #ffde76;
} */

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  background-color: #ffc107 !important;
  color: #fff !important;
}

.MuiIconButton-colorPickUp {
  color: #ffc107 !important;
}
