/*Deposit Form css*/
.hedrTxt{ font-size: 20px; color: #000; padding-left: 20px;font-weight: 600 }
.whitebg{ background: #fff;}
.align_right{ text-align: right; }
.txt_bold{ font-weight:500; }
.grylbl,.frmLbl,.wrp Label{color:#a4a9bd; font-size: 14px; font: weight 400;}
.lbl{ color:#a7acc0; font-size: 14px; font-weight:600;}
.tc{ font-size: 12px;}
.tc a{text-decoration: underline; }
.accordnWp  .spantxt{ font-size:11px; color:#a4a9bd; margin-left:43px;}
.accordnWp  label.lbltxt{ font-size:14px; color:#000; margin-left:27px; font-weight:600;}
.accordnWp .accordion .card{padding: 0; box-shadow: none; border:1px solid #acb0c3; 
    /* font-size: 0; */
     width:100%; text-align:left;}
/* .accordnWp  .accordion > .card:not(:last-of-type){ border-bottom: 1px solid rgba(0,0,0,.125);} */
.accordioncardWp{ width:100%;}
.accordnWp .accordion .card .card-header{ padding:8px; border-bottom:0; text-align: left; width:100%;}
.btnblue{background:#659f2f; border:2px solid #659f2f; font-size: 14px; font-weight:600;}
.btnblue:hover{ background:#659f2f; border:2px solid #659f2f; font-size: 14px; font-weight: 600;}
.accordnWp .radioOuterBkg{ border:none;background-color: transparent; width:10px;height:10px; }
.accordnWp .MuiRadio-colorSecondary.Mui-checked{ color:#659f2f!important;}
.accordnWp .PrivateRadioButtonIcon-root-5{ margin-top:10px;}
.accordnWp .MuiRadio-colorSecondary.Mui-checked:hover{ background-color: transparent!important;}
.MuiIconButton-colorSecondary:hover{ background-color: transparent!important;}
.accordnWp .cardHead{  min-height:55px; height:auto;}
.accordnWp .cardHead.active{ border:1px solid #659f2f; background-color: #659f2f; color:#fff;}
.accordnWp  .cardHead.active label.lbltxt{color:#fff!important; }
.accordnWp .collapse.show{  border:1px solid #659f2f;}
.inlineRadioWp .MuiFormGroup-root{ display:inline-block!important; }
.inlineRadioWp .MuiSvgIcon-root{ width:25px!important; height:25px!important; color:#659f2f;}
.paymentIconWp .payment-icon{ width:60px;}
.paymentIconWp{ margin-top:10px;}
.MuiRadio-colorSecondary.Mui-checked{ color:#659f2f!important; background:transparent;}
.MuiRadio-colorSecondary.Mui-checked:hover{ background-color: transparent;}
.PrivateSwitchBase-input-4:active{ background: #fff;}
.H10{ height:100px;}
.H18{ height: 180px;}
.accordnWp .form-control{ font-size: 14px!important; height:35px!important;}
.PriceLbl{ padding-left:40px;color: #659f2f!important}
@media only screen and (min-width :768px) and (max-width:1200px){
    .depositWp .whitebg.col-md-6{ width:80%; max-width: 80%; flex:80%; }
    .paymentIconWp .payment-icon{ width:30px; margin-top: 5px;}
}
@media only Screen and (max-width:767px){
    .PaymntCol{display: none;}
    .H10,.H18{ display:none;}
}
@media only Screen and (max-width:479px){
    .PaymntCol{ display: none;}
    .paymentIconWp .payment-icon{ width:30px; margin-top: 5px;}

}
